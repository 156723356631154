import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { withAuthentication } from "./service/session";

// pages for this product
import Login from "./components/Login";
import Events from "./components/Events";
import EventDetail from "./components/EventDetail";
import Layout from "./components/Layout";
import Meeting from "./components/Meeting";
import Stripe from "./components/Stripe";
import AuthActions from "./components/AuthActions";
import Features from "./components/Features";
import VideoCV from "./components/VideoCV";
import PromoCampaign from "./components/PromoCampaign";
import EventViewHandler from "./components/Events/EventViewHandler";

var hist = createBrowserHistory();

const RouteContainer = () => {
  return (
    <Router history={hist}>
      <Layout>
        <Switch>
          <Route path="/login" component={Login} />
          <Route exact path="/stripe/:type" component={Stripe} />
          <Route path="/viewClipBook" component={VideoCV} />
          <Route path="/promoCampaign" component={PromoCampaign} />
          <Route path="/viewEvent" component={EventViewHandler} />
          <Route path="/assignTicket" component={PromoCampaign} />

          <Route
            exact
            path="/events/:eventId&:ownerId&:userAgoraId&:batch"
            component={EventDetail}
          />
          <Route path="/meeting" component={Meeting} />
          <Route path="/authactions" component={AuthActions} />
          <Route path="/my-events" component={Events} />
          <Route path="/" component={Features} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default withAuthentication(RouteContainer);
