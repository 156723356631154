import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ReactMarkdown from 'react-markdown'
import { withFirebase } from "../../service/firebase";
import { If } from "../../common";
import VideoCVCard from "./VideoCVCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Scrollbars } from "react-custom-scrollbars";

import "./VideoCV.css";

import productStyle from "../../assets/jss/material-kit-pro-react/views/productStyle";
import { VIDEO_CV_TYPE } from "constants/video";
import VidioloVideoPlayer from "../VidioloVideoPlayer";

const useStyles = makeStyles(productStyle);

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const VProfile = (props) => {
  const classes = useStyles();
  const [videoCV, setVideoCV] = useState();
  const [value, setValue] = useState(0);
  const query = useQuery();
  const [playerSize, setPlayerSize] = useState("495px");
  const [contentHeight, setContentHeight] = useState(495);
  const [screen, setScreen] = useState({
    isWindow: false,
    isTablet: false,
    isMobile: false,
  });

  useEffect(() => {
    const userId = query.get("userId");
    const videoCvId = query.get("videoCvId");

    const manageVideoPlayerSize = () => {
      if (Number(window.innerWidth) < 800) {
        if (Number(window.innerWidth) < 495) {
          setContentHeight(Number(window.innerWidth) * 0.7);
        } else {
          setContentHeight(495);
        }
        setPlayerSize("100%");
      } else {
        setPlayerSize("495px");
        setContentHeight(495 * 0.7);
      }

      if (Number(window.innerWidth) > 1200) {
        setScreen((ss) => ({
          ...ss,
          isWindow: true,
          isTablet: false,
          isMobile: false,
        }));
      } else if (Number(window.innerWidth) > 800) {
        setScreen((ss) => ({
          isWindow: false,
          isTablet: true,
          isMobile: false,
        }));
      } else if (Number(window.innerWidth) > 495) {
        setScreen((ss) => ({
          isWindow: false,
          isTablet: false,
          isMobile: true,
        }));
      }
    };

    manageVideoPlayerSize();

    window.addEventListener("resize", manageVideoPlayerSize);

    if (userId && videoCvId) {
      const userDynoLink = props.firebase.getVideoCv();

      userDynoLink({
        videoCvPath: `/users/${userId}/video_cvs/${videoCvId}`,
      })
        .then((result) => {
          if (!result.data.error) {
            setVideoCV(result);
          }
        })
        .catch((error) => {
          console.error(error, "result error");
        });
    }
  }, []);

  return (
    <div className={classes.productPage}>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.containerVideo}>
          <div className={classNames(classes.main)}>
            <If
              condition={Boolean(videoCV)}
              otherwise={() => <span>Loading</span>}
              render={() => (
                <React.Fragment>
                  <div className="video-cv-user-info">
                    <div className="video-cv-user-info-avatar">
                      <div
                        style={{
                          display: "flex",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src={videoCV.data.videoCv.imageUrl}
                          style={{
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <h3
                          style={{
                            margin: 0,
                          }}
                        >
                          {videoCV.data.videoCv.displayName}
                        </h3>
                        <p style={{ margin: "0" }}>
                          {videoCV.data.videoCv.cvDescription}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#000",
                        color: "#FFF",
                        fontWeight: "400",
                        width: screen.isWindow ? "880px" : "495px",
                      }}
                    >
                      {videoCV.data.cvSections.map((section, index) => {
                        if (value === index) {
                          return (
                            <div
                              className="vidiolo-video-container"
                              key={section.cvSectionId}
                            >
                              <div className="vidiolo-video-player">
                                <VidioloVideoPlayer
                                  url={section.videoUrl}
                                  height={playerSize}
                                  width={playerSize}
                                  backgroundImage={section.imageList}
                                  videoMode={section.recordingMode}
                                  isPlaying={index !== 0}
                                />
                                <div
                                  className="vidiolo-video-cards-mobile"
                                  style={{
                                    height: `${
                                      contentHeight === 495
                                        ? 420
                                        : contentHeight + 10
                                    }px`,
                                  }}
                                >
                                  <div>
                                    {videoCV.data.cvSections.map(
                                      (section, index) => (
                                        <div
                                          key={section.cvSectionId}
                                          onClick={() => setValue(index)}
                                          className={`vidiolo-video-cards-mobile-icon ${
                                            value === index ? "active" : ""
                                          }`}
                                        >
                                          <If
                                            condition={Boolean(
                                              VIDEO_CV_TYPE[
                                                section.cvSectionType
                                              ]
                                            )}
                                            render={() => (
                                              <FontAwesomeIcon
                                                icon={
                                                  VIDEO_CV_TYPE[
                                                    section.cvSectionType
                                                  ].ICON
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="vidiolo-video-content"
                                style={{
                                  height: screen.isWindow ? '480px' : `${contentHeight}px`,
                                }}
                              >
                                <VideoCVCard
                                  data={videoCV.data.cvSections[value]}
                                  isHeader
                                />
                                <div
                                  style={{
                                    height: "calc(100% - 120px)",
                                  }}
                                >
                                  <Scrollbars>
                                  <p style={{ padding: "16px" }}>
                                    <ReactMarkdown>{section.description}</ReactMarkdown>
                                  </p>
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          );
                        }

                        return null;
                      })}
                    </div>
                    <div className="vidiolo-video-cards">
                      <Scrollbars>
                        {videoCV.data.cvSections.map((section, index) => (
                          <VideoCVCard
                            data={section}
                            isActive={value === index}
                            key={section.cvSectionId}
                            onClick={() => setValue(index)}
                          />
                        ))}
                      </Scrollbars>
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(VProfile);
