import React, {useCallback, useEffect, useState} from 'react';
import queryString from 'query-string';
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../service/firebase';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import '../Login/Login.css';

import {
  GridContainer,
  GridItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Snackbar,
  If
} from '../../common';

import loginPageStyle from "../../assets/jss/material-kit-pro-react/views/loginPageStyle";

const useStyles = makeStyles(loginPageStyle);

const AuthActions = ({firebase, location}) => {
  const [formError, setFormError] = React.useState(null);
  const [loginForm, setLoginForm] = React.useState({
    password: null,
    re_password: null
  });
  const [mode, setMode] = useState(null);
  const [successData, setSuccessData] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const updateVerifyEmail = useCallback(async (result) => {
    const updateVerify = firebase.updateVerifyByEmail();
    return await updateVerify({
      email: result.data.email
    });
  }, [firebase]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    setMode(params.mode);

    try {
      if (params.mode === 'verifyEmail') {
        setLoading(true);
        firebase.doVerifyEmail(params.oobCode,
          async (result) => {
            let verifyEmail = null;
            let timeout = setTimeout(async () => {
              verifyEmail = await updateVerifyEmail(result);
              setSuccessData({
                email: verifyEmail.data.email,
                message: "Your email has been verified. Thank you."
              });
              setLoading(false);
            }, 10000);
    
            verifyEmail = await updateVerifyEmail(result);
            clearTimeout(timeout);
            timeout = null;
            setSuccessData({
              email: verifyEmail.data.email,
              message: "Your email has been verified. Thank you."
            });
            setLoading(false);
        }, (error) => {
          setSuccessData({
            email: null,
            message: error.message
          });
          setLoading(false);
        });
      } else if (params.mode === 'resetPassword') {
        firebase.doCheckResetPassword(params.oobCode, (success)=> {
          setUserEmail(success);
        }, (error) => {
          setSuccessData({
            email: null,
            message: error.message
          });
        });
      }
    } catch (error) {
      setSuccessData({
        email: null,
        message: error.message
      });
      setLoading(false);
    }
  }, []);

  const classes = useStyles();

  const onFormChange = value => {
    setFormError(null);
    setLoginForm({
      ...loginForm,
      ...value
    })
  }

  const isPasswordValid = (password) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(password);

  const onUpdatePassword = () => {
    const params = queryString.parse(location.search);
    setFormError(null);
    if (!isPasswordValid(loginForm.password)) {
      setFormError("Password should contain at least one number, one uppercase character and one lowercase character");
    } else if (loginForm.password !== loginForm.re_password) {
      setFormError("Passwords should match");
    } else {
      firebase.doResetPassword(params.oobCode, loginForm.password, (success) => {
        window.location.href = process.env.DYNAMIC_LINK_LOGIN_PAGE;//'https://dev.links.vidiolo.com/loginpage';
      }, (error) => {
        setFormError(error.message);
      });
    }
  }

return (
    <div
        className={classes.pageHeader + ' vidiolo-bg-overlay-color'}
        style={{
          backgroundImage: "url(" + require("assets/img/bg_image.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader
                  color="primary"
                  signup
                  className={classes.cardHeader}
                >
                  <h4 className={classes.cardTitle}>
                    {mode === 'verifyEmail' ? 'Email Verification' : 'New Password'}
                  </h4>
                </CardHeader>
                <If
                  condition={mode === 'verifyEmail' || successData != null}
                  render={() => (
                    <If
                      condition={loading}
                      otherwise={() => (
                        <React.Fragment>
                      {successData?.email &&
                        <p className={classes.description + " " + classes.textCenter} style={{fontWeight: 'bold'}}>
                          {successData.email}
                        </p>
                      }
                      <p className={classes.description + " " + classes.textCenter}>
                        {successData?.message}
                      </p>
                      <div className={classes.textCenter}>
                        <Button color="primary" size="sm" style={{marginBottom: '20px'}} onClick={() => {
                          window.location.href = process.env.REACT_APP_DYNAMIC_LINK_LOGIN_PAGE;
                        }}>
                          OK
                        </Button>
                      </div>
                    </React.Fragment>
                      )}
                      render={() => (
                        <p className={classes.description + " " + classes.textCenter}>
                        Loading... Please wait...
                      </p>
                      )}
                    />
                    
                  )}
                />
                <If
                  condition={mode === 'resetPassword' && !successData}
                  render={() => (
                    <React.Fragment>
                      <CardBody signup>
                        <p style={{padding: '20px', textAlign: 'center', fontWeight: 'bold'}}>{userEmail}</p>
                        <Input
                          id="pass"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: "Password",
                            type: "password",
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon className={classes.inputIconsColor}>
                                  lock_utline
                                </Icon>
                              </InputAdornment>
                            ),
                            onChange: (event) => {
                              onFormChange({
                                password: event.target.value
                              })
                            }
                          }}
                        />
                        <Input
                          id="pass_confirm"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: "Re-Password",
                            type: "password",
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon className={classes.inputIconsColor}>
                                  lock_utline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: "off",
                            onChange: (event) => onFormChange({
                              re_password: event.target.value
                            })
                          }}
                        />
                      </CardBody>
                      <div className={classes.textCenter}>
                        <Button color="primary" size="sm" style={{marginBottom: '20px'}} onClick={() => onUpdatePassword()}>
                          Update
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                />
              </Card>
            </GridItem>
          </GridContainer>
          <If
            condition={Boolean(formError)}
            render={() => (
              <Snackbar
                message={
                  <span>
                    <b>WARNING ALERT:</b> {formError}
                  </span>
                }
                close
                color="danger"
                icon="info_outline"
              />
            )}
          />
        </div>
      </div>
  );
}

export default compose(withRouter, withFirebase)(AuthActions);
