import React from "react";
import { func, object, number } from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  GridItem,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Info,
  Badge,
  If,
} from "../../common";
import CATEGORIES from "../../constants/category";
import "./EventItem.css";
import EventProfileStar from "./EventProfileStar";

const EventItem = ({ event, cardClasses, agoraUserId, isListItem }) => (
  <GridItem
    xs={12}
    sm={isListItem ? 12 : 6}
    md={isListItem ? 12 : 4}
    lg={isListItem ? 12 : 3}
  >
    <Card className="event-item">
      <CardHeader image>
        <Link
          to={`/events/${event.eventId}&${event.eventOwnerId}&${agoraUserId}&0`}
          className="event-item-img"
        >
          <img src={event.eventImageUrl} alt="..." />
        </Link>

        <div className="event-item-date">
          <div>{moment(event.eventDate.toDate()).format("ddd, DD MMM")}</div>
          <div>{moment(event.eventDate.toDate()).format("h:mm A")}</div>
        </div>

        <If
          condition={Boolean(event.isPrivate)}
          render={() => (
            <div style={{ position: "absolute", bottom: "10px", left: "10px" }}>
              <Badge
                color="primary"
                className="event-item-badge"
              >
                INVITATION ONLY
              </Badge>
            </div>
          )}
        />

        <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
          <Badge
            color="primary"
            className={`event-item-badge ${event.isFree ? "" : "price"}`}
          >
            {event.isFree
              ? "FREE"
              : `${event.eventPrice.amount} ${event.eventPrice.currency}`}
          </Badge>
        </div>
      </CardHeader>
      <CardBody>
        <div>
          <div className={cardClasses.author}>
            <a href="#" onClick={(e) => e.preventDefault()}>
              <img
                src={event.eventOwnerImageUrl}
                alt={event.eventOwnerDisplayName}
                className={cardClasses.imgRaised + " " + cardClasses.avatar}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "5px",
                }}
              >
                <strong style={{ marginLeft: "5px", lineHeight: "15px" }}>
                  {event.eventOwnerDisplayName}
                </strong>
                <EventProfileStar starCount={event.eventOwnerRating} />
              </div>
            </a>
          </div>
        </div>
        <Info>
          <h6
            className={cardClasses.cardCategory + " multiline-ellipsis"}
            style={{ marginBottom: "0" }}
          >
            {event?.eventCategories
              ?.map((category) => CATEGORIES[category].title)
              .join(", ")}
          </h6>
        </Info>
        <h4
          className={cardClasses.cardTitle + " multiline-ellipsis"}
          style={{ marginTop: "0" }}
        >
          <Link
            to={`/events/${event.eventId}&${event.eventOwnerId}&${agoraUserId}&0`}
          >
            {event.eventTitle}
          </Link>
        </h4>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  </GridItem>
);

EventItem.propTypes = {
  event: object,
  cardClasses: object,
  onClick: func,
  agoraUserId: number,
};

export default EventItem;
