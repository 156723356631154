import React, { useEffect, useRef, useState, useCallback } from "react";
import orderBy from "lodash/orderBy";
import VideoHostPlayer from "./VideoHostPlayer";
import { If } from "common";
import VideoAttendePlayer from "./VideoAttendePlayer";
import gridSize from "../../helper/grid-helper";

const VideoGridScreen = ({
  meetingState,
  localVideoTrack,
  localShareTrack,
  localAudioTrack,
  userAgoraId,
  showMeeting,
  localStreamStatus,
  ownerAgoraId,
  getParticipantStream,
  sharedUser,
}) => {
  const pageCount = useRef(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    const calculateParticipantListCount = () => {
      const currentHight = Number(window.innerHeight) - 65;
      const currentWidth =
        Number(window.innerWidth) -
        Number(meetingState.showParticipant ? 300 : 0);
      const minHeight = 150;
      const maxHeight = 300;
      const participantCount = meetingState.participantList.filter(
        (item) => item.join
      ).length;

      const sizes = gridSize(
        currentWidth,
        currentHight,
        participantCount + 1,
        minHeight,
        maxHeight,
        16 / 9
      );
      pageCount.current = sizes.length;

      setSizes(sizes);
    };

    calculateParticipantListCount();
    window.addEventListener("resize", calculateParticipantListCount);
  }, [meetingState.participantList, meetingState.showParticipant]);

  const getAttendeeList = useCallback(
    (showHostAttendee, showLocalAttendee, currentList) => {
      if (sizes?.length) {
        const listPageCount =
          currentPage === 0
            ? sizes[currentPage]?.numUsers - 1
            : sizes[currentPage]?.numUsers;
        let showingPage = 0;
        const currentSplicedList = [...currentList];
        const isHost = Number(userAgoraId) === Number(ownerAgoraId);

        if (showHostAttendee) {
          showingPage = showingPage + 1;
        }

        if (showLocalAttendee) {
          showingPage = showingPage + 1;
        }

        const startIndex =
          currentPage === 0
            ? currentPage
            : currentPage * listPageCount - showingPage - (isHost ? 1 : 0);
        const endIndex =
          currentPage === 0
            ? listPageCount - showingPage
            : (currentPage + 1) * listPageCount - showingPage;

        const reducedList = currentSplicedList.slice(startIndex, endIndex);

        if (currentPage !== 0 && reducedList.length === 0) {
          setCurrentPage(currentPage - 1);
        }

        return reducedList.map((participant) => {
          const currentStream = getParticipantStream(
            participant.user.ticketAgoraId
          );
          return (
            <VideoAttendePlayer
              key={participant.user.ticketAgoraId}
              videoTrack={currentStream && currentStream.videoTrack}
              user={participant.user}
              sharedId={meetingState.sharedId}
              streamStatus={{
                video: participant.video,
                audio: participant.audio,
              }}
              containerSize={getSizeOfImage()}
            />
          );
        });
      }
    },
    [meetingState.sharedId, sizes, currentPage, userAgoraId, ownerAgoraId]
  );

  const getSizeOfImage = useCallback(() => {
    return {
      imageHeight: sizes[currentPage]?.imageHeight,
      imageWidth: sizes[currentPage]?.imageWidth,
    };
  }, [sizes, currentPage]);

  return (
    <div
      className="grid-container"
      style={
        sizes.length
          ? {
              width: sizes[currentPage]?.cols * getSizeOfImage().imageWidth,
              height: sizes[currentPage]?.rows * getSizeOfImage().imageHeight,
            }
          : {}
      }
    >
      <If
        condition={currentPage !== 0}
        render={() => (
          <div className="list-arrow-vertical-container left-side">
            <div
              className="list-arrow-container left-position"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <div className="list-left-arrow"></div>
            </div>
          </div>
        )}
      />
      <If
        condition={sizes.length > 1 && sizes.length - 1 !== currentPage}
        render={() => (
          <div className="list-arrow-vertical-container right-side">
            <div
              className="list-arrow-container left-position"
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <div className="list-right-arrow"></div>
            </div>
          </div>
        )}
      />
      <If
        condition={
          meetingState.isHostJoined && showMeeting() && currentPage === 0
        }
        render={() => {
          const currentVideoTrack = meetingState.user?.isCamera
            ? localVideoTrack
            : localShareTrack || meetingState.mainScreen.videoTrack;
          const currentAudioTrack = meetingState.user?.isCamera
            ? localAudioTrack
            : meetingState.mainScreen.audioTrack;

          return (
            <VideoAttendePlayer
              videoTrack={currentVideoTrack}
              isLocalUser={meetingState.mainScreen.isLocalUser}
              containerSize={getSizeOfImage()}
              user={sharedUser}
              streamStatus={{
                video:
                  Number(ownerAgoraId) !== Number(userAgoraId)
                    ? Boolean(currentVideoTrack)
                    : Boolean(currentVideoTrack?._enabled || currentVideoTrack?._mediaStreamTrack?.enabled),
                audio:
                  Number(ownerAgoraId) !== Number(userAgoraId)
                    ? Boolean(currentAudioTrack)
                    : Boolean(currentAudioTrack?._enabled || currentAudioTrack?._mediaStreamTrack?.enabled),
              }}
            />
          );
        }}
      />
      <If
        condition={
          meetingState.participantList.filter((item) => item.join).length > 0
        }
        render={() => {
          const currentList = orderBy(meetingState.participantList, [
            "user.isCamera",
            "user.displayName",
          ]).filter(
            (participant) =>
              participant.join &&
              Number(meetingState.sharedId) !==
                Number(participant.user.ticketAgoraId) &&
              Number(ownerAgoraId) !== Number(participant.user.ticketAgoraId) &&
              Number(userAgoraId) !== Number(participant.user.ticketAgoraId)
          );
          const showHostAttendee = Boolean(
            meetingState.isHostJoined &&
              meetingState.hostAttendeeScreen &&
              showMeeting()
          );
          const showLocalAttendee =
            !meetingState.isHost &&
            Number(meetingState.sharedId) !== Number(userAgoraId);
          return (
            <React.Fragment>
              {/* If host is not presenter */}
              <If
                condition={showHostAttendee && currentPage === 0}
                render={() => {
                  return (
                    <VideoAttendePlayer
                      videoTrack={meetingState.hostAttendeeScreen.videoTrack}
                      user={meetingState.hostAttendeeScreen.user}
                      isLocalUser={meetingState.hostAttendeeScreen.isLocalUser}
                      streamStatus={
                        Number(ownerAgoraId) === Number(userAgoraId)
                          ? localStreamStatus
                          : meetingState?.hostAttendeeScreen?.status
                      }
                      containerSize={getSizeOfImage()}
                    />
                  );
                }}
              />

              {/* For local user */}
              <If
                condition={showLocalAttendee && currentPage === 0}
                render={() => {
                  return (
                    <VideoAttendePlayer
                      videoTrack={localVideoTrack}
                      user={meetingState.user}
                      isLocalUser={true}
                      streamStatus={localStreamStatus}
                      containerSize={getSizeOfImage()}
                    />
                  );
                }}
              />
              {getAttendeeList(
                showHostAttendee,
                showLocalAttendee,
                currentList
              )}
            </React.Fragment>
          );
        }}
      />
    </div>
  );
};

export default VideoGridScreen;
