/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useCallback, useState } from "react";
import { arrayOf, func, object, string, number } from "prop-types";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";
import classnames from "classnames";
import AgoraRTC from "agora-rtc-sdk-ng";
import { makeStyles } from "@material-ui/core/styles";
import { StreamChat } from "stream-chat";
import useAgora from "./hooks/useAgora";
import useLocalTrackState from "./hooks/useLocalStream";
import {
  MEETING_STATUS,
  VIDEO_COMMAND_TYPE,
  LOG_EVENT_TYPE,
  STREAM_TYPE,
  VIDEO_PROPERTIES,
  STREAM_ROLES,
} from "../../constants/video";
import { If, Button, Dialog } from "common";
import VideoControls from "./VideoControls";
import VideoParticipantList from "./VideoParticipantList";
import { getInitialMeetingStatus } from "./helper/helper";
import RTMClient from "../../service/agora/agora-rtm";
import { withFirebase } from "../../service/firebase";
import VideoAlert from "./VideoAlert";
import CountDown from "../CountDown";
import VideoHeader from "./VideoHeader";
import VideoAttendePlayer from "./VideoAttendePlayer";
import VideoWideScreen from "./VideoWideScreen";
import VideoGridScreen from "./VideoGridScreen";
import VideoChat from "../VideoChat";
import Logger from '../../service/logger';
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import "./canvas.css";
import VideoHostControl from "./VideoHostControl";

let logger = null;

const useStyles = makeStyles(javascriptStyles);

Array.prototype.forEachAsync = async function (fn) {
  for (let t of this) {
    await fn(t);
  }
};

AgoraRTC.setLogLevel(3);

const client = AgoraRTC.createClient({
  codec: VIDEO_PROPERTIES.CODEC,
  mode: VIDEO_PROPERTIES.MODE,
  role: STREAM_ROLES.HOST,
});

const clientMessageChannel = new RTMClient();
let alertTimeout = null;
let heartbeatInterval = null;

const propTypes = {
  appId: string.isRequired,
  attendeeList: arrayOf(object).isRequired,
  channelList: arrayOf(object).isRequired,
  channel: string.isRequired,
  rtmChannel: string.isRequired,
  configuration: object.isRequired,
  event: object.isRequired,
  firebase: object.isRequired,
  meetingState: object.isRequired,
  ownerAgoraId: number.isRequired,
  rtmToken: string.isRequired,
  tokens: object.isRequired,
  updateMeetingState: func.isRequired,
  userAgoraId: string.isRequired,
  batch: string.isRequired
};

const VideoCall = ({
  channel,
  appId,
  firebase,
  userAgoraId,
  tokens,
  channelList,
  rtmToken,
  ownerAgoraId,
  event,
  attendeeList,
  meetingState,
  updateMeetingState,
  configuration,
  rtmChannel,
  batch
}) => {
  logger = new Logger({
    context : 'VideoCallPage'
  }, firebase);
  const {
    localAudioTrack,
    localVideoTrack,
    localShareTrack,
    leave,
    join,
    joinState,
    remoteUsersStatus,
    remoteUsers,
    playShareScreen,
    stopShareScreen,
    localShareAudiTrack,
    rtcError,
    setRTCError,
    updateHookMeetingState,
    setRemoteUsers
  } = useAgora(client, logger, firebase, event, batch);
  const {
    localStreamStatus,
    setLocalStreamStatus,
    setLocalVideo,
    setLocalAudio,
  } = useLocalTrackState(localVideoTrack, localAudioTrack);
  const [showDetail, setShowDetail] = useState(false);
  const [devices, setDevices] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState({
    selectedVideoInputs: "",
    selectedAudioInputs: "",
    selectedAudioOutputs: "",
  });
  const [recordingData, setRecordingData] = useState(null);
  const classes = useStyles();
  const [recordingLoading, setRecordingLoading] = useState(false);

  const listennerState = React.useRef(meetingState);
  const [chatClient, setChatClient] = useState();

  useEffect(() => {
    listennerState.current = meetingState;
    updateHookMeetingState(meetingState);
  }, [meetingState]);

  useEffect(() => {
    const currentParticipantList = [...meetingState.participantList];
    if (attendeeList.length !== currentParticipantList.length) {
      attendeeList.forEach((user) => {
        if (user.ticketAgoraId) {
          const isExist = currentParticipantList.find(
            (item) =>
              Number(item.user.ticketAgoraId) === Number(user.ticketAgoraId)
          );
          if (!isExist) {
            currentParticipantList.push({
              user,
              audio: false,
              audioRequestWaiting: false,
              video: false,
              videoRequestWaiting: false,
              share: false,
              shareRequestWaiting: false,
              enabled: true,
              join: Number(userAgoraId) === Number(user.ticketAgoraId),
            });
          }
        }
      });

      updateMeetingState({
        participantList: currentParticipantList,
      });
    }
  }, [attendeeList]);

  const deviceManagement = useCallback(() => {
    AgoraRTC.getDevices()
      .then((devices) => {
        const videoInputs = [];
        const audioInputs = [];
        const audioOutputs = [];
        devices.forEach((item) => {
          if (item.kind === "videoinput") {
            videoInputs.push(item);
          } else if (
            item.kind === "audioinput" &&
            item.deviceId !== "default"
          ) {
            audioInputs.push(item);
          } else if (
            item.kind === "audiooutput" &&
            item.deviceId !== "default"
          ) {
            audioOutputs.push(item);
          }
        });

        setSelectedDevices({
          selectedVideoInputs: videoInputs.length && videoInputs[0]?.deviceId,
          selectedAudioInputs: audioInputs.length && audioInputs[0]?.deviceId,
          selectedAudioOutputs:
            audioOutputs.length && audioOutputs[0]?.deviceId,
        });

        setDevices({
          videoInputs,
          audioInputs,
          audioOutputs,
        });
      })
      .catch((error) => {
        logger.error(error);
      });
  }, []);

  useEffect(() => {
    const waitingSeconds = configuration.waiting_lounge_duration_in_mins * 60;
    const extraTime = configuration.additional_duration_in_mins * 60;
    const heartbeatPeriodInSecs = configuration.heartbeatPeriodInSecs * 1000;
    const isHost = Number(userAgoraId) === Number(ownerAgoraId);
    let user = null;

    if (isHost) {
      user = {
        imageUrl: event.eventOwnerImageUrl,
        displayName: event.eventOwnerDisplayName,
        ticketAgoraId: userAgoraId,
        id: event.eventOwnerId,
      };
    } else {
      user = attendeeList.find(
        (participant) =>
          Number(participant.ticketAgoraId) === Number(userAgoraId)
      );
    }

    const calculateParticipantListCount = () => {
      const { listPageCount } = listennerState.current;
      const currentHight = Number(window.innerHeight);
      let currentPageCount = listPageCount || 3;

      if (615 > currentHight) {
        currentPageCount = 3;
      } else if (615 <= currentHight && currentHight < 739) {
        currentPageCount = 4;
      } else if (739 <= currentHight && currentHight < 865) {
        currentPageCount = 5;
      } else {
        currentPageCount = 6;
      }

      if (listPageCount !== currentPageCount) {
        updateMeetingState({
          listPageCount: currentPageCount,
        });
      }
    };

    const mockAttendeeList = [];

    // if (process.env.REACT_APP_PROJECT_ID === "vidiolo-dev-68f8f") {
    //   for (let index = 0; index < 24; index++) {
    //     mockAttendeeList.push({
    //       user: {
    //         agoraUserId: index,
    //         displayName: `Test ${index}`,
    //         id: index,
    //         imageUrl:
    //           "https://firebasestorage.googleapis.com/v0/b/vidiolo-dev-68f8f.appspot.com/o/users%2F1dIryMBZIISbfUant3AijlwMBUJ2%2Favatar%2F1616906498111.jpg?alt=media&token=2fbec051-d349-4747-9fff-0a7f27db1cca",
    //         ticketAgoraId: index,
    //       },
    //       audio: false,
    //       audioRequestWaiting: false,
    //       video: false,
    //       videoRequestWaiting: false,
    //       share: false,
    //       shareRequestWaiting: false,
    //       enabled: true,
    //       join: true,
    //     });
    //   }
    // }

    const currentAttendeeList = attendeeList.map((user) => ({
      user,
      audio: false,
      audioRequestWaiting: false,
      video: false,
      videoRequestWaiting: false,
      share: false,
      shareRequestWaiting: false,
      enabled: true,
      join: Number(userAgoraId) === Number(user.ticketAgoraId),
    }));

    let isModerator = false;
    const sentUser = currentAttendeeList.find(part => Number(part.user.ticketAgoraId) === Number(userAgoraId));
    if (sentUser) {
      isModerator = event.moderators?.indexOf(sentUser.user.id) > -1;
    }

    updateMeetingState({
      user,
      participantList: [...currentAttendeeList, ...mockAttendeeList],
      isHost,
      isModerator,
      isHostJoined: Number(userAgoraId) === Number(ownerAgoraId),
      sharedId: Number(ownerAgoraId),
      listPageCount: 3,
      currentPage: 0,
      meetingStatus: getInitialMeetingStatus(
        event,
        waitingSeconds,
        extraTime,
        firebase.getTimeStamp()
      ),
      waitingSeconds,
      isGrid: false,
      extraTime,
      dialogRespondDurationInSecs:
        Number(configuration.dialogRespondDurationInSecs) * 100000,
      heartbeatPeriodInSecs,
      alertNotification:
        isHost && !event.curtainUp
          ? {
            isOpen: true,
            message: MEETING_STATUS.CURTAIN_MEETING,
            confirmTitle: "OK",
          }
          : null,
    });

    calculateParticipantListCount();
    window.addEventListener("resize", calculateParticipantListCount);

    deviceManagement();

    join(
      appId,
      channel,
      JSON.parse(tokens),
      userAgoraId,
      configuration,
      JSON.parse(channelList)
    );

    const listener = firebase.onAuthUserListener((authUser) => {
      const currentChatClient = StreamChat.getInstance(
        process.env.REACT_APP_STREAM_CHAT_API,
        { enableInsights: true }
      );
      currentChatClient.connectUser(
        {
          id: authUser.userId,
          name: authUser.name,
          image: authUser.userImageUrl,
        },
        authUser.streamChatToken
      );
      setChatClient(currentChatClient);
    });

    return () => {
      leaveMeeting();
      listener();
    };
    // eslint-disable-next-line max-len
  }, []);

  const leaveMeeting = useCallback(() => {
    clearInterval(heartbeatInterval);
    heartbeatInterval = null;
    clientMessageChannel.leaveChannel(rtmChannel).then(() => {
      if (clientMessageChannel.channels[rtmChannel]) {
        clientMessageChannel.channels[rtmChannel].joined = false;
        clientMessageChannel.channels[rtmChannel] = null;
      }
    });
    clientMessageChannel.logout();
    leave();
  }, [leave, rtmChannel]);

  useEffect(() => {
    const { sharedId, isHost } = meetingState;

    if (joinState) {
      clientMessageChannelInit();
      subscribeMessageEvents();

      setLocalVideo(isHost || meetingState.user?.isCamera);
      setLocalAudio(isHost);

      if (Number(sharedId) === Number(userAgoraId)) {
        updateMainScreen(localVideoTrack, localAudioTrack, true);
      } else {
        remoteUsers.forEach((user) => {
          if (Number(sharedId) === Number(user.uid)) {
            updateMainScreen(user.videoTrack, user.audioTrack, false);
          }
        });
      }
    }
  }, [joinState]);

  const getSharedUser = useCallback(() => {
    const { sharedId, user, participantList } = meetingState;
    if (Number(sharedId) === Number(userAgoraId)) {
      return user;
    } else if (Number(sharedId) === Number(ownerAgoraId)) {
      return {
        imageUrl: event.eventOwnerImageUrl,
        displayName: event.eventOwnerDisplayName,
        ticketAgoraId: ownerAgoraId,
        id: event.eventOwnerId,
      };
    } else {
      const currentUser = participantList.find(
        (participant) =>
          Number(participant.user.ticketAgoraId) === Number(sharedId)
      );
      return currentUser && currentUser.user;
    }
  }, [meetingState]);

  const updateMainScreen = useCallback(
    async (videoTrack, audioTrack, isLocalUser) => {
      updateMeetingState({
        mainScreen: {
          videoTrack: videoTrack,
          audioTrack: audioTrack,
          isLocalUser: isLocalUser,
        },
      });
    },
    [updateMeetingState]
  );

  useEffect(() => {
    if (Number(ownerAgoraId) !== Number(userAgoraId)) {
      const isHostJoined =
        remoteUsers.find((user) => Number(user.uid) === Number(ownerAgoraId)) !=
        null;
      updateMeetingState({
        isHostJoined,
      });
    }

    if (remoteUsers.length > 0) {
      let hostAttendeeScreen = meetingState.hostAttendeeScreen
        ? { ...meetingState.hostAttendeeScreen }
        : null;

      remoteUsers.forEach((user) => {
        if (user.audioTrack && !meetingState.user?.isCamera) {
          user.audioTrack.play();
        }

        if (Number(user.uid) === Number(meetingState.sharedId)) {
          updateMainScreen(user.videoTrack, user.audioTrack, false);
        }

        if (
          Number(user.uid) !== Number(meetingState.sharedId) &&
          Number(user.uid) === Number(ownerAgoraId)
        ) {
          if (hostAttendeeScreen) {
            hostAttendeeScreen = {
              ...hostAttendeeScreen,
              videoTrack: user.videoTrack,
              audioTrack: user.audioTrack,
              status: {
                video: Boolean(user.videoTrack),
                audio: Boolean(user.audioTrack),
              },
            };
          }
        }

        updateUserData(user.uid, user.videoTrack, user.audioTrack);
      });

      updateMeetingState({
        hostAttendeeScreen,
      });
    }
  }, [remoteUsers]);

  useEffect(() => {
    const { sharedId } = meetingState;

    let hostAttendeeScreen = { ...meetingState.hostAttendeeScreen };

    if (Number(sharedId) === Number(userAgoraId)) {
      updateMainScreen(localVideoTrack, localAudioTrack, true);
    } else {
      const sharedUser = remoteUsers.find(
        (user) => Number(sharedId) === Number(user.uid)
      );

      if (sharedUser) {
        updateMainScreen(sharedUser.videoTrack, sharedUser.audioTrack, false);
      }
    }

    if (Number(sharedId) === Number(ownerAgoraId)) {
      hostAttendeeScreen = null;
    } else {
      if (Number(userAgoraId) === Number(ownerAgoraId)) {
        hostAttendeeScreen = {
          videoTrack: localVideoTrack,
          audioTrack: localAudioTrack,
          user: {
            imageUrl: event.eventOwnerImageUrl,
            displayName: event.eventOwnerDisplayName,
          },
          isLocalUser: true,
        };
      } else {
        const sharedUser = remoteUsers.find(
          (user) => Number(ownerAgoraId) === Number(user.uid)
        );
        if (sharedUser) {
          hostAttendeeScreen = {
            videoTrack: sharedUser.videoTrack,
            audioTrack: sharedUser.audioTrack,
            user: {
              imageUrl: event.eventOwnerImageUrl,
              displayName: event.eventOwnerDisplayName,
            },
            isLocalUser: false,
            status: {
              video: sharedUser.videoTrack,
              audio: sharedUser.audioTrack,
            },
          };
        }
      }
    }

    if (recordingData) {
      updateRecording(sharedId);
    }

    updateMeetingState({
      hostAttendeeScreen,
    });

    if (client.connectionState === "CONNECTED") {
      client.setRemoteVideoStreamType(
        Number(sharedId),
        STREAM_TYPE.HIGH
      ).then();

      remoteUsers.forEachAsync(async (user) => {
        if (
          (Number(sharedId) !== Number(user.uid) &&
            Number(sharedId) !== Number(userAgoraId))
        ) {
          await client.setRemoteVideoStreamType(
            Number(user.uid),
            STREAM_TYPE.LOW
          );
        }
      });

      setRemoteUsers(() => [...remoteUsers])
    }
  }, [meetingState.sharedId]);

  const clearTimeoutLogic = useCallback(() => {
    clearTimeout(alertTimeout);
    alertTimeout = null;
  }, []);

  // eslint-disable-next-line complexity
  const manageHostMessage = useCallback(
    (message, senderAgoraId, currentMeetingState) => {
      const { participantList } = currentMeetingState || meetingState;
      const currentList = [...participantList];
      const userIndex = currentList.findIndex(
        (item) => Number(item.user.ticketAgoraId) === Number(senderAgoraId)
      );
      const currentMessage = message.action;

      switch (currentMessage) {
        case VIDEO_COMMAND_TYPE.ENABLE:
          currentList[userIndex].enabled = true;
          break;
        case VIDEO_COMMAND_TYPE.DISABLE:
          currentList[userIndex].enabled = false;
          break;
        case VIDEO_COMMAND_TYPE.REVOKE_PRESENTATION:
          currentList[userIndex].share = false;
          clearTimeoutLogic();
          break;
        case VIDEO_COMMAND_TYPE.UNMUTE_REJECT:
          currentList[userIndex].audioRequestWaiting = false;
          clearTimeoutLogic();
          break;
        case VIDEO_COMMAND_TYPE.UNMUTE_VIDEO_REJECT:
          currentList[userIndex].videoRequestWaiting = false;
          clearTimeoutLogic();
          break;
        case VIDEO_COMMAND_TYPE.PRESENT_REJECT:
          currentList[userIndex].shareRequestWaiting = false;
          clearTimeoutLogic();
          break;
        case VIDEO_COMMAND_TYPE.PRESENTATION_APPROVED:
          currentList.forEach((currentItem) => {
            currentItem.share = false;
            currentItem.shareRequestWaiting = false;
          });
          if (userIndex > -1) {
            currentList[userIndex].share = true;
            currentList[userIndex].shareRequestWaiting = false;
          }
          break;

        default:
          break;
      }

      updateMeetingState({
        participantList: currentList,
      });
    },
    [meetingState, updateMeetingState, clearTimeoutLogic]
  );

  // eslint-disable-next-line complexity
  const manageUserMessage = useCallback((message, currentMeetingState) => {
      const { dialogRespondDurationInSecs, sharedId, participantList, user } = currentMeetingState || meetingState;
      const currentMessage = message.action;
      const currentUser = participantList.find(
        (participant) =>
          Number(participant.user.ticketAgoraId) === Number(userAgoraId)
      );

      switch (currentMessage) {
        case VIDEO_COMMAND_TYPE.MUTE:
          setLocalAudio(false);
          break;
        case VIDEO_COMMAND_TYPE.UNMUTE:
          if (currentUser && currentUser.user.isCamera) {
            setLocalAudio(true);
          } else {
            updateMeetingState({
              alertNotification: {
                isOpen: true,
                message: currentMessage,
              },
            });
            clearTimeoutLogic();
            alertTimeout = setTimeout(() => {
              sendMessageToUser(
                {
                  action: VIDEO_COMMAND_TYPE.UNMUTE_REJECT,
                  targetId: Number(ownerAgoraId),
                  time: new Date().getTime(),
                },
                Number(ownerAgoraId)
              );
              updateMeetingState({
                alertNotification: null,
              });
              clearTimeoutLogic();
            }, dialogRespondDurationInSecs);
          }
          break;
        case VIDEO_COMMAND_TYPE.UNMUTE_VIDEO_REVOKE:
        case VIDEO_COMMAND_TYPE.UNMUTE_REVOKE:
          updateMeetingState({
            alertNotification: null,
          });
          break;
        case VIDEO_COMMAND_TYPE.UNMUTE_VIDEO:
          if (currentUser && currentUser.user.isCamera) {
            setLocalVideo(true);
          } else {
            clearTimeoutLogic();
            updateMeetingState({
              alertNotification: {
                isOpen: true,
                message: currentMessage,
              },
            });
            alertTimeout = setTimeout(() => {
              sendMessageToChannel(
                {
                  action: VIDEO_COMMAND_TYPE.UNMUTE_VIDEO_REJECT,
                  targetId: Number(ownerAgoraId),
                  time: new Date().getTime(),
                },
                Number(ownerAgoraId)
              );
              updateMeetingState({
                alertNotification: null,
              });
              clearTimeoutLogic();
            }, dialogRespondDurationInSecs);
          }
          break;
        case VIDEO_COMMAND_TYPE.MUTE_VIDEO:
          setLocalVideo(false);
          break;
        case VIDEO_COMMAND_TYPE.PRESENT:
          if ((currentUser && currentUser.user.isCamera) || (localVideoTrack?._enabled && localAudioTrack?._enabled) || Number(ownerAgoraId) === Number(userAgoraId)) {
            setLocalVideo(true);
            setLocalAudio(!user.isCamera);
            if (Number(ownerAgoraId) !== Number(userAgoraId)) {
              sendMessageToChannel({
                action: VIDEO_COMMAND_TYPE.PRESENTATION_APPROVED,
                time: new Date().getTime(),
              });
            }
            updateMeetingState({
              sharedId: Number(userAgoraId),
            });
          } else {
            clearTimeoutLogic();
            updateMeetingState({
              alertNotification: {
                isOpen: true,
                message: currentMessage,
              },
            });
            alertTimeout = setTimeout(() => {
              sendMessageToChannel(
                {
                  action: VIDEO_COMMAND_TYPE.PRESENT_REJECT,
                  targetId: Number(ownerAgoraId),
                  time: new Date().getTime(),
                },
                Number(ownerAgoraId)
              );
              updateMeetingState({
                alertNotification: null,
              });
              clearTimeoutLogic();
            }, dialogRespondDurationInSecs);
          }
          break;
        case VIDEO_COMMAND_TYPE.REVOKE_PRESENTATION:
          if (currentUser) {
            updateUserData(userAgoraId, true, true, {share: false}, currentMeetingState);
          }

          updateMeetingState({
            sharedId: ownerAgoraId,
            alertNotification: null,
          });

          stopShareScreen();
          break;
        case VIDEO_COMMAND_TYPE.PRESENTATION_APPROVED:
          setLocalVideo(true);
          setLocalAudio(true);
          if (currentUser) {
            updateUserData(userAgoraId, true, true, {share: true}, currentMeetingState);
          }
          updateMeetingState({
            sharedId: userAgoraId,
          });
          break;
        case VIDEO_COMMAND_TYPE.ENABLE:
          updateMeetingState({
            locked: false,
          });
          break;
        case VIDEO_COMMAND_TYPE.DISABLE:
          setLocalVideo(false);
          setLocalAudio(false);

          updateMeetingState({
            locked: true,
          });
          break;
        case VIDEO_COMMAND_TYPE.STATUS_RESPONSE:
          if (
            message.info.presenterId &&
            sharedId !== message.info.presenterId
          ) {
            updateMeetingState({
              sharedId: message.info.presenterId,
            });
          }
          break;

        default:
          break;
      }
    },
    // eslint-disable-next-line max-len
    [
      meetingState,
      localAudioTrack,
      updateMeetingState,
      clearTimeoutLogic,
      localVideoTrack,
      ownerAgoraId,
      userAgoraId,
      sendMessageToUser,
      sendMessageToChannel,
    ]
  );

  const updateUserData = useCallback(
    (id, isVideoOn, isAudioOn, additionalData, existState) => {
      const currentList = existState ? [...existState.participantList] : [...meetingState.participantList];
      const userIndex = currentList.findIndex(
        (item) => Number(item.user.ticketAgoraId) === Number(id)
      );

      if (userIndex !== -1) {
        currentList[userIndex].video = isVideoOn;
        currentList[userIndex].videoRequestWaiting = false;
        currentList[userIndex].audio = isAudioOn;
        currentList[userIndex].audioRequestWaiting = false;

        currentList[userIndex] = {...currentList[userIndex], ...additionalData};
      }

      updateMeetingState({
        participantList: currentList,
      });
    },
    [meetingState, updateMeetingState]
  );

  // eslint-disable-next-line complexity
  const manageChannelMessage = useCallback(
    (message, agoraId, currentMeetingState) => {
      const currentMessage = message.action;
      const { isHost, sharedId, isModerator } = currentMeetingState || meetingState;

      switch (currentMessage) {
        case VIDEO_COMMAND_TYPE.REVOKE_PRESENTATION:
        case VIDEO_COMMAND_TYPE.PRESENT_REJECT:
          if (isHost || isModerator) {
            manageHostMessage(message, message.targetId, listennerState.current);
          }

          updateMeetingState({
            sharedId: ownerAgoraId,
          });

          stopShareScreen();

          updateUserData(message.targetId, true, true, null, currentMeetingState || meetingState);
          break;
        case VIDEO_COMMAND_TYPE.PRESENTATION_APPROVED:
          updateMeetingState({
            sharedId: agoraId,
          });

          if (isHost || isModerator) {
            manageHostMessage(message, agoraId, listennerState.current);
          }
          break;
        case VIDEO_COMMAND_TYPE.STATUS_REQUEST:
          // eslint-disable-next-line no-case-declarations
          const presenceData = {
            videoOn: Boolean(localVideoTrack && localVideoTrack._enabled),
            voiceOn: Boolean(localAudioTrack && localAudioTrack._enabled),
            presenterId: Number(sharedId),
            orientation: "landscape",
          };

          sendMessageToUser(
            {
              action: VIDEO_COMMAND_TYPE.STATUS_RESPONSE,
              targetId: Number(agoraId),
              info: presenceData,
              time: new Date().getTime(),
            },
            Number(agoraId)
          );
          break;

        default:
          if (isHost) {
            manageHostMessage(message, agoraId, listennerState.current);
          }
          break;
      }
    },
    // eslint-disable-next-line max-len
    [
      meetingState,
      updateMeetingState,
      ownerAgoraId,
      updateUserData,
      localVideoTrack,
      localAudioTrack,
      sendMessageToUser,
      manageHostMessage,
    ]
  );

  const sendMessageToUser = useCallback(
    (message, agoraId) => {
      const { isHost, user } = meetingState;

      if (!clientMessageChannel._logined) {
        logger.error("Please Login First");

        return;
      }

      if (
        !clientMessageChannel.channels[rtmChannel] ||
        (clientMessageChannel.channels[rtmChannel] &&
          !clientMessageChannel.channels[rtmChannel].joined)
      ) {
        logger.error("Please Join first");
      }

      clientMessageChannel
        .sendPeerMessage(JSON.stringify(message), agoraId)
        .then(() => {
          if (isHost) {
            manageHostMessage(message, agoraId, listennerState.current);
          }

          firebase.updateEventLog(
            user.id,
            event.eventId,
            message,
            agoraId,
            userAgoraId
          );
        })
        .catch((err) => {
          logger.error(err);
        });
    },
    [
      meetingState,
      userAgoraId,
      rtmChannel,
      firebase,
      event.eventId,
      manageHostMessage,
    ]
  );

  const sendMessageToChannel = useCallback(
    (message, userId) => {
      const { isHost, isModerator } = meetingState;

      if (!clientMessageChannel._logined) {
        logger.error("Please Login First");

        return;
      }

      if (
        !clientMessageChannel.channels[rtmChannel] ||
        (clientMessageChannel.channels[rtmChannel] &&
          !clientMessageChannel.channels[rtmChannel].joined)
      ) {
        logger.error("Please Join first");
      }

      clientMessageChannel
        .sendChannelMessage(JSON.stringify(message), rtmChannel)
        .then(() => {
          if (isHost || (isModerator && userId)) {
            manageChannelMessage(message, userId);
          } else {
            manageUserMessage(message, listennerState.current);
          }
        })
        .catch((err) => {
          logger.error(err);
        });
    },
    [meetingState, rtmChannel, manageChannelMessage, manageUserMessage]
  );

  useEffect(() => {
    if (remoteUsersStatus) {
      const currentList = [...meetingState.participantList];
      if (remoteUsersStatus.joined) {
        const userIndex = currentList.findIndex(
          (item) =>
            Number(item.user.ticketAgoraId) ===
            Number(remoteUsersStatus.user.uid)
        );

        if (userIndex !== -1) {
          if (Number(ownerAgoraId) !== Number(remoteUsersStatus.user.uid)) {
            currentList[userIndex].join = true;
          }
        }

        updateMeetingState({
          participantList: currentList,
        });
      } else {
        let currentSharedId = meetingState.sharedId;

        const userIndex = currentList.findIndex(
          (item) =>
            Number(item.user.ticketAgoraId) ===
            Number(remoteUsersStatus.user.uid)
        );

        if (userIndex !== -1) {
          currentList[userIndex].join = false;

          if (
            Number(meetingState.sharedId) === Number(remoteUsersStatus.user.uid)
          ) {
            currentList[userIndex].share = false;
          }
        }

        if (
          Number(meetingState.sharedId) === Number(remoteUsersStatus.user.uid)
        ) {
          currentSharedId = ownerAgoraId;
        }

        if (Number(ownerAgoraId) === Number(remoteUsersStatus.user.uid)) {
          currentSharedId = ownerAgoraId;
          setLocalAudio(false);
        }

        updateMeetingState({
          participantList: currentList,
          sharedId: currentSharedId,
        });
      }
    }
  }, [remoteUsersStatus]);

  const subscribeMessageEvents = useCallback(() => {
    clientMessageChannel.on("MessageFromPeer", (message, peerId) => {
      logger.info(["MessageFromPeer", message, Number(peerId)]);

      let isModerator = false;
      const sentUser = listennerState.current.participantList.find(part => Number(part.user.ticketAgoraId) === Number(peerId));
      if (sentUser) {
        isModerator = event.moderators?.indexOf(sentUser.user.id) > -1;
      }

      if (Number(ownerAgoraId) === Number(peerId) || isModerator) {
        manageUserMessage(JSON.parse(message.text), listennerState.current);
      } else {
        manageHostMessage(
          JSON.parse(message.text),
          Number(peerId),
          listennerState.current
        );
      }
    });

    clientMessageChannel.on("ChannelMessage", (message) => {
      if (JSON.parse(message.args[0].text).action != "HEARTBEAT") {
        logger.info(JSON.parse(message.args[0].text));
      }
      
      manageChannelMessage(
        JSON.parse(message.args[0].text),
        message.args[1],
        listennerState.current
      );
    });

    clientMessageChannel.on("ConnectionStateChanged", (message) => {
      if (message === "ABORTED") {
        handleForceLogoutMeeting();
      }
    });

    // eslint-disable-next-line max-len
  }, [
    ownerAgoraId,
    manageUserMessage,
    manageHostMessage,
    manageChannelMessage,
    rtmChannel,
    updateMeetingState,
  ]);

  const clientMessageChannelInit = useCallback(() => {
    clientMessageChannel.init(appId);
    clientMessageChannel
      .login(userAgoraId.toString(), rtmToken)
      .then(() => {
        logger.info("login messaging");
        clientMessageChannel._logined = true;

        if (
          clientMessageChannel.channels[rtmChannel] ||
          (clientMessageChannel.channels[rtmChannel] &&
            clientMessageChannel.channels[rtmChannel].joined)
        ) {
          logger.error("You already joined");
          return;
        }

        clientMessageChannel
          .joinChannel(rtmChannel)
          .then(() => {
            logger.info(["join messaging", userAgoraId]);
            clientMessageChannel.channels[rtmChannel].joined = true;
            sendMessageToChannel(
              {
                action: VIDEO_COMMAND_TYPE.STATUS_REQUEST,
                targetId: Number(userAgoraId),
                time: new Date().getTime(),
              },
              Number(userAgoraId)
            );

            heartbeatInterval = setInterval(() => {
              sendMessageToChannel({
                action: VIDEO_COMMAND_TYPE.HEARTBEAT,
                time: new Date().getTime(),
              });
            }, meetingState.heartbeatPeriodInSecs);

            clientMessageChannel.getMembers(rtmChannel).then((users) => {
              const currentList = [...meetingState.participantList];

              users.forEach((element) => {
                // eslint-disable-next-line max-nested-callbacks
                const userIndex = currentList.findIndex(
                  (item) => Number(item.user.ticketAgoraId) === Number(element)
                );

                if (userIndex !== -1) {
                  if (Number(ownerAgoraId) !== Number(element)) {
                    currentList[userIndex].join = true;
                  }
                }
              });
              updateMeetingState({
                participantList: currentList,
              });
            });
          })
          .catch((err) => {
            logger.error(err);
          });
      })
      .catch((err) => {
        logger.error(err);
      });
    // eslint-disable-next-line max-len
  }, [
    appId,
    rtmChannel,
    meetingState.participantList,
    ownerAgoraId,
    rtmToken,
    sendMessageToChannel,
    updateMeetingState,
    userAgoraId,
  ]);

  const handleWaitingCountDownCallback = useCallback(() => {
    const { meetingStatus, isHostJoined } = meetingState;
    let currentState = {};

    if (meetingStatus === MEETING_STATUS.NOT_STARTED) {
      currentState = {
        meetingStatus: MEETING_STATUS.STARTED,
      };
    } else if (
      meetingStatus === MEETING_STATUS.STARTED &&
      !isHostJoined &&
      !event.isFree
    ) {
      currentState = {
        alertNotification: {
          isOpen: true,
          message: MEETING_STATUS.END_OF_SESSION_WITHOUT_HOST,
        },
      };
    }

    updateMeetingState(currentState);
  }, [meetingState, updateMeetingState]);

  const handleMeetingCountDownCallback = useCallback(() => {
    const { meetingStatus } = meetingState;
    let currentState = {};

    if (meetingStatus === MEETING_STATUS.STARTED) {
      currentState = {
        meetingStatus: MEETING_STATUS.IN_END_OF_MEETING,
      };
    } else if (meetingStatus === MEETING_STATUS.IN_END_OF_MEETING) {
      currentState = {
        meetingStatus: MEETING_STATUS.IN_EXTRA_TIME,
      };
    }

    updateMeetingState(currentState);
  }, [meetingState, updateMeetingState]);

  const handleForceEndMeetingCountDownCallback = useCallback(() => {
    updateMeetingState({
      alertNotification: {
        isOpen: true,
        message: MEETING_STATUS.FORCE_END_MEETING,
      },
    });

    setTimeout(() => {
      window.location.href = "/events";
    }, 5000);
  }, [updateMeetingState]);

  const handleForceLogoutMeeting = useCallback(() => {
    updateMeetingState({
      meetingStatus: MEETING_STATUS.DUPLICATE_USER,
      alertNotification: {
        isOpen: true,
        message: MEETING_STATUS.DUPLICATE_USER,
      },
    });

    setTimeout(() => {
      window.location.href = "/events";
    }, 3000);
  }, [updateMeetingState]);

  // eslint-disable-next-line complexity
  const onNotificationCancel = useCallback(() => {
    const { alertNotification } = meetingState;
    let currentAction = null;

    switch (alertNotification && alertNotification.message) {
      case VIDEO_COMMAND_TYPE.UNMUTE_VIDEO:
        currentAction = VIDEO_COMMAND_TYPE.UNMUTE_VIDEO_REJECT;
        break;
      case VIDEO_COMMAND_TYPE.UNMUTE:
        currentAction = VIDEO_COMMAND_TYPE.UNMUTE_REJECT;
        break;
      case VIDEO_COMMAND_TYPE.PRESENT:
        currentAction = VIDEO_COMMAND_TYPE.PRESENT_REJECT;
        break;

      default:
        break;
    }

    if (currentAction) {
      sendMessageToUser(
        {
          action: currentAction,
          targetId: Number(ownerAgoraId),
          time: new Date().getTime(),
        },
        Number(ownerAgoraId)
      );
    }

    updateMeetingState({ alertNotification: null });
  }, [meetingState, ownerAgoraId, sendMessageToUser, updateMeetingState]);

  // eslint-disable-next-line complexity
  const manageAlertNotification = useCallback(() => {
    const { alertNotification, user } = meetingState;

    switch (alertNotification?.message) {
      case VIDEO_COMMAND_TYPE.PRESENT:
        sendMessageToChannel({
          action: VIDEO_COMMAND_TYPE.PRESENTATION_APPROVED,
          time: new Date().getTime(),
        });
        updateMeetingState({
          sharedId: userAgoraId,
        });

        firebase.updateEventLog(
          user.id,
          event.eventId,
          LOG_EVENT_TYPE.PRESENT_APPROVE,
          userAgoraId,
          ""
        );
        clearTimeoutLogic();
        break;
      case VIDEO_COMMAND_TYPE.UNMUTE_VIDEO:
        setLocalVideo(true);
        break;
      case VIDEO_COMMAND_TYPE.UNMUTE:
        setLocalAudio(true);
        break;
      case MEETING_STATUS.END_OF_SESSION_WITHOUT_HOST:
        break;
      case MEETING_STATUS.FORCE_END_MEETING:
        window.location.href = "/events";
        break;
      case "REQUEST_PRESENT_SOMEONE":
      case "REQUEST_REVOKE_PRESENT":
        alertNotification.action();
        break;

      default:
        break;
    }

    updateMeetingState({
      alertNotification: null,
    });
    // eslint-disable-next-line max-len
  }, [
    meetingState,
    updateMeetingState,
    sendMessageToChannel,
    userAgoraId,
    firebase,
    event.eventId,
    clearTimeoutLogic,
    localVideoTrack,
    localAudioTrack,
  ]);

  const getParticipantStream = useCallback(
    (agoraId) => {
      return remoteUsers.find((user) => Number(user.uid) === Number(agoraId));
    },
    [remoteUsers]
  );

  const handlePresent = useCallback(() => {
    sendMessageToChannel(
      {
        action: VIDEO_COMMAND_TYPE.REVOKE_PRESENTATION,
        targetId: Number(userAgoraId),
        time: new Date().getTime(),
      },
      Number(userAgoraId)
    );

    updateUserData(userAgoraId, true, true);
  }, [userAgoraId, sendMessageToChannel, updateUserData]);

  const handleSwitchDevice = useCallback(
    (type, deviceId) => {
      if (type === "video") {
        localVideoTrack
          .setDevice(deviceId)
          .then((result) => {
            setSelectedDevices({
              ...selectedDevices,
              selectedVideoInputs: deviceId,
            });
          })
          .catch((error) => {
            logger.error(error);
          });
      } else if (type === "audio-input") {
        localAudioTrack
          .setDevice(deviceId)
          .then((result) => {
            setSelectedDevices({
              ...selectedDevices,
              selectedAudioInputs: deviceId,
            });
          })
          .catch((error) => {
            logger.error(error);
          });
      } else if (type === "audio-output") {
        localAudioTrack
          .setPlaybackDevice(deviceId)
          .then((result) => {
            setSelectedDevices({
              ...selectedDevices,
              selectedAudioOutputs: deviceId,
            });
          })
          .catch((error) => {
            logger.error(error);
          });
      }
    },
    [localVideoTrack, localAudioTrack, selectedDevices]
  );

  const switchList = useCallback(
    (isBack, listCount) => {
      const { currentPage, listPageCount, grid } = meetingState;
      const currentPageCount = listPageCount * grid;
      if (listCount > currentPageCount) {
        if (isBack) {
          if (currentPage !== 0) {
            updateMeetingState({
              currentPage: currentPage - 1,
            });
          }
        } else {
          const totalPages = Math.ceil(listCount / currentPageCount);
          if (currentPage + 1 < totalPages) {
            updateMeetingState({
              currentPage: currentPage + 1,
            });
          }
        }
      }
    },
    [meetingState, updateMeetingState]
  );

  const getGrid = useCallback(() => {
    switch (meetingState.grid) {
      case 1:
        return "column-1";
      case 2:
        return "column-2";
      case 3:
        return "column-3";
      default:
        return "column-1";
    }
  });

  const getAttendeeList = useCallback(
    (showHostAttendee, showLocalAttendee, currentList) => {
      const { currentPage, listPageCount, grid } = meetingState;
      const currentPageCount = listPageCount * grid;
      let showingPage = 0;
      const currentSplicedList = [...currentList];

      if (showHostAttendee) {
        showingPage = showingPage + 1;
      }

      if (showLocalAttendee) {
        showingPage = showingPage + 1;
      }

      const startIndex =
        currentPage === 0
          ? currentPage
          : currentPage * currentPageCount - showingPage;
      const endIndex =
        currentPage === 0
          ? currentPageCount - showingPage
          : (currentPage + 1) * currentPageCount - showingPage;

      const reducedList = currentSplicedList.slice(startIndex, endIndex);

      if (currentPage !== 0 && reducedList.length === 0) {
        updateMeetingState({
          currentPage: currentPage - 1,
        });
      }

      return reducedList.map((participant) => {
        const currentStream = getParticipantStream(
          participant.user.ticketAgoraId
        );
        return (
          <VideoAttendePlayer
            key={participant.user.ticketAgoraId}
            videoTrack={currentStream && currentStream.videoTrack}
            user={participant.user}
            sharedId={meetingState.sharedId}
            streamStatus={{
              video: participant.video,
              audio: participant.audio,
            }}
          />
        );
      });
    },
    [meetingState, updateMeetingState]
  );

  const showMeeting = useCallback(() => {
    const { isHost, user } = meetingState;

    if (isHost || user.isCamera) {
      return true;
    }

    return event.curtainUp;
  }, [meetingState, event]);

  const startRecording = useCallback(() => {
    setRecordingLoading(true);
    const recording = firebase.startRecording();
    recording({
      eventId: event.eventId,
      channel: channel,
      maxResolutionUid: meetingState.sharedId,
      eventOwnerId: event.eventOwnerId,
    })
      .then((result) => {
        setRecordingLoading(false);
        setRecordingData(result);
      })
      .catch((error) => {
        logger.error(error);
      });
  }, [firebase, channel, event, meetingState]);

  const updateRecording = useCallback(
    (maxResolutionUid) => {
      const recording = firebase.updateRecording();
      recording({
        resourceId: recordingData?.data.resourceId,
        channel: channel,
        maxResolutionUid: maxResolutionUid,
        sid: recordingData?.data.sid,
      })
        .then((result) => { })
        .catch((error) => {
          logger.error(error);
        });
    },
    [firebase, recordingData, channel]
  );

  const stopRecording = useCallback(() => {
    setRecordingLoading(true);
    const recording = firebase.stopRecording();
    recording({
      resourceId: recordingData?.data.resourceId,
      channel: channel,
      sid: recordingData?.data.sid,
      eventOwnerId: event.eventOwnerId,
      eventId: event.eventId,
    })
      .then((result) => {
        setRecordingLoading(false);
        setRecordingData(null);
      })
      .catch((error) => {
        logger.error(error);
      });
  }, [firebase, channel, recordingData]);

  return (
    <div id="video-container" className="video-container">
      <If
        condition={Boolean(
          !meetingState.isHostJoined ||
          meetingState.meetingStatus === MEETING_STATUS.NOT_STARTED ||
          !showMeeting()
        )}
        render={() => (
          <div className="host-loading">
            <h6 className="host-loading-title">{event?.eventTitle}</h6>
            <h6>
              {meetingState.meetingStatus === MEETING_STATUS.STARTED
                ? "Waiting for host..."
                : "The event has not started yet!"}
            </h6>
            <h6 className="host-loading-timer">
              <CountDown
                callback={() => handleWaitingCountDownCallback()}
                callbackCount={
                  meetingState.meetingStatus === MEETING_STATUS.STARTED
                    ? meetingState.waitingSeconds
                    : 0
                }
                serverEndTime={event.eventDate?.seconds * 1000}
                serverNow={firebase.getTimeStamp().seconds * 1000}
              />
            </h6>
          </div>
        )}
      />
      <If
        condition={meetingState.isHost && !event.curtainUp}
        render={() => (
          <div className="host-cutain-in-button-container">
            <div className="host-cutain-in-button">
              <p>{`${meetingState.participantList.filter((item) => item.join).length
                } attendee(s) in waiting room`}</p>
              <Button
                onClick={() => {
                  firebase.eventUpdate(event.eventOwnerId, event.eventId, {
                    curtainUp: true,
                  });
                }}
                color="primary"
                round
                className={
                  classes.modalSmallFooterFirstButton +
                  " " +
                  classes.modalSmallFooterSecondButton
                }
              >
                <span style={{ fontWeight: "700" }}>Let Attendee In</span>
              </Button>
            </div>
          </div>
        )}
      />

      <div
        style={{
          width: "100%",
          height: "calc(100% - 65px)",
          position: "relative",
          transition: "margin-right .3s",
          marginRight: meetingState.showParticipant ? "300px" : 0,
        }}
      >
        <If
          condition={
            meetingState.isHostJoined &&
            meetingState.meetingStatus !== MEETING_STATUS.NOT_STARTED &&
            showMeeting()
          }
          render={() => (
            <VideoHeader
              event={event}
              handleForceEndMeetingCountDownCallback={handleForceEndMeetingCountDownCallback}
              handleMeetingCountDownCallback={handleMeetingCountDownCallback}
              isHost={meetingState.isHost || meetingState.isModerator}
              isHostJoined={meetingState.isHostJoined}
              meetingStatus={meetingState.meetingStatus}
              onClickAttendeesList={(show) => {
                updateMeetingState({
                  showParticipantVideoList: show,
                });
              }}
              showParticipantVideoList={meetingState.showParticipantVideoList}
              participantListCount={
                meetingState.participantList.filter((item) => item.join).length
              }
              serverTime={firebase.getTimeStamp().seconds}
              waitingSeconds={meetingState.waitingSeconds}
              extraTime={meetingState.extraTime}
              showParticipantList={meetingState.showParticipant}
              handleParticipantList={() =>
                updateMeetingState({
                  showParticipant: !meetingState.showParticipant,
                })
              }
              isGrid={meetingState.isGrid}
              switchGridView={(show) => {
                updateMeetingState({
                  isGrid: show,
                });
              }}
            />
          )}
        />
        <div
          className={classnames("video-canvas", {
            "is-host-joined":
              meetingState.meetingStatus !== MEETING_STATUS.NOT_STARTED &&
              meetingState.isHostJoined &&
              showMeeting(),
            "grid-view": meetingState.isGrid,
          })}
          id="video-canvas"
        >
          <If
            condition={Boolean(meetingState.isHost || meetingState.isModerator)}
            render={() => (
              <VideoHostControl
                ownerAgoraId={ownerAgoraId}
                sharedId={meetingState.sharedId}
                sendMessageToUser={sendMessageToUser}
                sendMessageToChannel={sendMessageToChannel}
                isHost={meetingState.isHost}
                cameraList={meetingState.participantList.filter((item) => item.join && item.user.isCamera)}
              />
            )}
          />
          <If
            condition={Boolean(meetingState.isGrid)}
            otherwise={() => (
              <VideoWideScreen
                meetingState={meetingState}
                localVideoTrack={localVideoTrack}
                localShareTrack={localShareTrack}
                getGrid={getGrid}
                userAgoraId={userAgoraId}
                showMeeting={showMeeting}
                switchList={switchList}
                localStreamStatus={localStreamStatus}
                ownerAgoraId={ownerAgoraId}
                getAttendeeList={getAttendeeList}
              />
            )}
            render={() => (
              <VideoGridScreen
                meetingState={meetingState}
                localVideoTrack={localVideoTrack}
                localAudioTrack={localAudioTrack}
                localShareTrack={localShareTrack}
                userAgoraId={userAgoraId}
                showMeeting={showMeeting}
                localStreamStatus={localStreamStatus}
                ownerAgoraId={ownerAgoraId}
                getAttendeeList={getAttendeeList}
                getParticipantStream={getParticipantStream}
                sharedUser={getSharedUser()}
              />
            )}
          />
        </div>
      </div>
      <div className={`participant-list ${meetingState.showParticipant ? "active" : ""}`}>
        <div className="participant-list-wrap">
          <div className="participant-item-title">
            <span
              onClick={() => updateMeetingState({ showParticipant: false })}
              className="ag-btn btn-participant exitBtn"
              title="Cancel"
            >
              <i className="icon-Forward" />
            </span>
          </div>
          <If
            condition={Boolean(meetingState.isHost || meetingState.isModerator)}
            otherwise={() => (
              <If
                condition={Boolean(chatClient)}
                render={() => (
                  <div
                    style={{
                      height: "calc(100% - 50px)",
                    }}
                  >
                    <div className="attende-list-sub-title">Chat</div>
                    <VideoChat
                      chatClient={chatClient}
                      channelName={event?.livestreamChannelId}
                    />
                  </div>
                )}
              />
            )}
            render={() => (
              <ReflexContainer orientation="horizontal">
                <ReflexElement minSize={200}>
                  <div className="attende-list-sub-title">Attendee List</div>
                  <div className="attendee-list-scroll ">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 20px",
                      }}
                    >
                      <VideoParticipantList
                        list={meetingState.participantList}
                        sendMessageToChannel={sendMessageToChannel}
                        sendMessageToUser={sendMessageToUser}
                        setCommonState={(item) => updateMeetingState(item)}
                        getStream={getParticipantStream}
                        userAgoraId={userAgoraId}
                        isModerator={meetingState.isModerator}
                      />
                    </div>
                  </div>
                </ReflexElement>

                <ReflexSplitter />

                <ReflexElement minSize={200}>
                  <If
                    condition={Boolean(chatClient)}
                    render={() => (
                      <React.Fragment>
                        <div className="attende-list-sub-title">Chat</div>
                        <VideoChat
                          chatClient={chatClient}
                          channelName={event?.livestreamChannelId}
                        />
                      </React.Fragment>
                    )}
                  />
                </ReflexElement>
              </ReflexContainer>
            )}
          />
        </div>
      </div>
      <VideoControls
        audioTrack={localAudioTrack}
        handlePresent={handlePresent}
        isHost={meetingState.isHost}
        isHostJoined={meetingState.isHostJoined && showMeeting()}
        isPresenting={Number(meetingState.sharedId) === Number(userAgoraId)}
        leave={leaveMeeting}
        locked={meetingState.locked}
        videoTrack={localVideoTrack}
        setLocalStreamStatus={setLocalStreamStatus}
        streamStatus={localStreamStatus}
        showDetail={showDetail}
        onShowEventDetail={() => setShowDetail(!showDetail)}
        event={event}
        localShareTrack={localShareTrack}
        localShareAudiTrack={localShareAudiTrack}
        playShareScreen={playShareScreen}
        stopShareScreen={() => {
          stopShareScreen();
          setLocalStreamStatus(true, true);
        }}
        devices={devices}
        handleSwitchDevice={handleSwitchDevice}
        sharedUser={getSharedUser()}
        selectedDevices={selectedDevices}
        startRecording={startRecording}
        stopRecording={stopRecording}
        recordingData={recordingData}
        recordingLoading={recordingLoading}
      />
      <VideoAlert
        alertNotification={meetingState.alertNotification}
        confirmTitle={
          (meetingState.meetingStatus === MEETING_STATUS.IN_EXTRA_TIME &&
            "Go to events") ||
          (meetingState.meetingStatus === MEETING_STATUS.DUPLICATE_USER && "OK")
        }
        manageAlertNotification={manageAlertNotification}
        onCancel={onNotificationCancel}
        setCommonState={(item) => updateMeetingState(item)}
        showCancel={
          event.curtainUp &&
          meetingState.meetingStatus !== MEETING_STATUS.IN_EXTRA_TIME &&
          meetingState.meetingStatus !== MEETING_STATUS.DUPLICATE_USER
        }
      />
      <Dialog
        open={Boolean(rtcError)}
        setOpen={() => setRTCError(null)}
        onConfirm={() => setRTCError(null)}
        content="Permission denied by the system. Please give web browser access to be able to share your screen."
      />
    </div>
  );
};

VideoCall.displayName = "VideoCall";
VideoCall.propTypes = propTypes;

export default withFirebase(VideoCall);
