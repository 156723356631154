import React, { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const EventViewHandler = () => {
  const query = useQuery();

  useEffect(() => {
    const eventId = query.get("eventId");
    const eventOwnerId = query.get("eventOwnerId");

    window.location.href = `/events/${eventId}&${eventOwnerId}&undefined&0`;
  }, []);

  return (
    <div />
  );
};

export default EventViewHandler;
