import React from "react";
import { Card, CardBody, GridItem, GridContainer, Info } from "../../common";
import { makeStyles } from "@material-ui/core/styles";
import Star from "@material-ui/icons/StarRate";
import { Link } from "react-router-dom";
import moment from "moment";
import { getDurationString } from "../../helper/time-helper";
import CATEGORIES from "../../constants/category";
import cardStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards";
import "./EventItem.css";

const cardsUseStyles = makeStyles(cardStyle);
const LastEvent = ({ event, agoraUserId }) => {
  const cardClasses = cardsUseStyles();
  return (
    <GridItem>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem sm={3}>
              <Link
                to={`/events/${event.eventId}&${event.eventOwnerId}&${agoraUserId}&0`}
                className="event-item-img"
              >
                <img
                  src={event.eventImageUrl}
                  alt="..."
                  style={{ width: "100%" }}
                />
              </Link>
            </GridItem>
            <GridItem sm={9}>
              <GridContainer>
                <GridItem xs={8}>
                  <Info>
                    <h6
                      className={
                        cardClasses.cardCategory + " multiline-ellipsis"
                      }
                      style={{ marginBottom: "0" }}
                    >
                      {event?.eventCategories
                        ?.map((category) => CATEGORIES[category].title)
                        .join(", ")}
                    </h6>
                  </Info>
                  <h4
                    className={cardClasses.cardTitle + " multiline-ellipsis"}
                    style={{ marginTop: "0" }}
                  >
                    <Link
                      to={`/events/${event.eventId}&${event.eventOwnerId}&${agoraUserId}&0`}
                    >
                      {event.eventTitle}
                    </Link>
                  </h4>
                </GridItem>
                <GridItem xs={4}>
                  <div>
                    <div className={cardClasses.author}>
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img
                          src={event.eventOwnerImageUrl}
                          alt={event.eventOwnerDisplayName}
                          className={
                            cardClasses.imgRaised + " " + cardClasses.avatar
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "5px",
                          }}
                        >
                          <strong
                            style={{ marginLeft: "5px", lineHeight: "15px" }}
                          >
                            {event.eventOwnerDisplayName}
                          </strong>
                          <span>
                            <Star
                              style={{
                                verticalAlign: "bottom",
                                color: "orange",
                              }}
                            />
                            {event.eventOwnerNumRatings}
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <hr />
              <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={4}>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <Info>
                      <i className="icon-Date" style={{ fontSize: "22px" }} />
                    </Info>
                    {moment(event.eventDate.toDate()).format("ddd, DD MMM")}
                  </span>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} lg={4}>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <Info>
                      <i className="icon-Clock" style={{ fontSize: "22px" }} />
                    </Info>
                    {moment(event.eventDate.toDate()).format("hh:mm A")}
                  </span>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} lg={4}>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <Info>
                      <i
                        className="icon-Duration"
                        style={{ fontSize: "22px" }}
                      />
                    </Info>
                    {getDurationString(event.eventDuration)}
                  </span>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} lg={4}>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <Info>
                      <i
                        className="icon-Language"
                        style={{ fontSize: "22px" }}
                      />
                    </Info>
                    English
                  </span>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} lg={4}>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <Info>
                      <i
                        className="icon-CreditCard"
                        style={{ fontSize: "22px" }}
                      />
                    </Info>
                    {event.eventPrice.amount}{" "}
                    {event.eventPrice.currency}
                  </span>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} lg={4}>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <Info>
                      <i className="icon-Seats" style={{ fontSize: "22px" }} />
                    </Info>
                    {event.eventSeats} Seats
                  </span>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default LastEvent;
