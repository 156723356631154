/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withFirebase } from "../../service/firebase";
import { withAuthorization } from "../../service/session";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import { GridContainer, NavPills, Snackbar, If, Primary } from "../../common";

import productStyle from "../../assets/jss/material-kit-pro-react/views/productStyle";
import cardStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards";
import EventItem from "./EventItem";
import LastEvent from "./LastEvent";

const useStyles = makeStyles(productStyle);
const cardsUseStyles = makeStyles(cardStyle);

const Events = (props) => {
  const classes = useStyles();
  const cardClasses = cardsUseStyles();
  const [eventList, setEventList] = React.useState();
  const [eventHostList, setEventHostList] = React.useState();
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let subscribe = null;
    let subscribeHostEvents = null;
    const listener = props.firebase.onAuthUserListener((authUser) => {
      setUser(authUser);
      subscribe = props.firebase.events(authUser.uid).onSnapshot((snap) => {
        const data = snap.docs.map((doc) => doc.data());
        setEventList(data);
        if (!authUser.isPro) {
          setLoading(false);
        }
      });

      if (authUser.isPro) {
        subscribeHostEvents = props.firebase
          .hostEvents(authUser.uid)
          .onSnapshot((snap) => {
            const data = snap.docs.map((doc) => doc.data());
            console.error(data);
            setEventHostList(data);
            setLoading(false);
          });
      }
    });

    return () => {
      listener();
      if (subscribe) {
        subscribe();
      }

      if (subscribeHostEvents) {
        subscribeHostEvents();
      }
    };
  }, []);

  return (
    <If
      condition={user?.isPro ? eventHostList && eventList : eventList}
      otherwise={() => (
        <div className="vidiolo-loading">
          <CircularProgress />
          <Primary>Loading...</Primary>
        </div>
      )}
      render={() => (
        <div className={classes.productPage}>
          <div className={classNames(classes.section, classes.sectionGray)}>
            <div className={classes.container}>
              <div className={classNames(classes.main)}>
                {user?.isPro ? (
                  <NavPills
                    color="rose"
                    tabs={[
                      {
                        tabButton: "Hosting",
                        tabContent: (
                          <GridContainer style={{ marginTop: "20px" }}>
                            <If
                              condition={eventHostList.length === 0 && !loading}
                              render={() => (
                                <Snackbar
                                  message={
                                    <span>
                                      You have no published events at present.
                                      To add an event or publish any draft
                                      events please go to the Vidiolo App which
                                      is available on the Google Play Store or
                                      in the Apple App Store if you haven’t
                                      downloaded it yet.
                                    </span>
                                  }
                                  color="info"
                                  icon="info_outline"
                                />
                              )}
                            />
                            {eventHostList.map((event) => (
                              <EventItem
                                key={event.eventId}
                                event={event}
                                cardClasses={cardClasses}
                                agoraUserId={event.eventOwnerAgoraId}
                              />
                            ))}
                          </GridContainer>
                        ),
                      },
                      {
                        tabButton: "Attending",
                        tabContent: (
                          <GridContainer style={{ marginTop: "20px" }}>
                            <If
                              condition={eventList.length === 0 && !loading}
                              render={() => (
                                <Snackbar
                                  message={
                                    <span>
                                      To browse and buy events please login to
                                      the Vidiolo App which is available on the
                                      Google Play Store or in the Apple App
                                      Store if you haven’t downloaded it yet.
                                    </span>
                                  }
                                  color="info"
                                  icon="info_outline"
                                />
                              )}
                            />
                            {eventList.map((data) => (
                              <EventItem
                                key={data.event.eventId}
                                event={data.event}
                                cardClasses={cardClasses}
                                agoraUserId={data.ticketAgoraId}
                              />
                            ))}
                          </GridContainer>
                        ),
                      },
                    ]}
                  />
                ) : (
                  <GridContainer style={{ marginTop: "20px" }}>
                    <If
                      condition={eventList.length === 0 && !loading}
                      render={() => (
                        <Snackbar
                          message={
                            <span>
                              To browse and buy events please login to the
                              Vidiolo App which is available on the Google Play
                              Store or in the Apple App Store if you haven’t
                              downloaded it yet.
                            </span>
                          }
                          color="info"
                          icon="info_outline"
                        />
                      )}
                    />
                    <If
                      condition={eventList.length}
                      render={() => (
                        <LastEvent
                          event={eventList[0].event}
                          agoraUserId={eventList[0].ticketAgoraId}
                        />
                      )}
                    />
                    {eventList.map((data) => (
                      <EventItem
                        key={data.event.eventId}
                        event={data.event}
                        cardClasses={cardClasses}
                        agoraUserId={data.ticketAgoraId}
                      />
                    ))}
                  </GridContainer>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

const condition = (authUser) => authUser;

export default compose(withFirebase, withAuthorization(condition))(Events);
