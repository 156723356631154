import {DEFAULT_OPTIONS, LEVEL, LOG_LEVEL} from '../../constants/logger';
import {buildMessage} from './helper';

let logLevel = LOG_LEVEL[LEVEL.DEBUG];

class Logger {
  static setLevel (newLevel) {
    if (Object.values(LOG_LEVEL).includes(newLevel)) {
      logLevel = newLevel;
    } else {
      global.console.error(`Invalid log level. Allowed values are ${Object.values(LOG_LEVEL).join(', ')}`);
    }
  }

  constructor (options = {}, firebase) {
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    };
    this.firebase = firebase;
  }

  debug = (...args) => this.log(LEVEL.DEBUG, ...args);

  info = (...args) => this.log(LEVEL.INFO, ...args);

  warn = (...args) => this.log(LEVEL.WARN, ...args);

  warning = (...args) => this.log(LEVEL.WARNING, ...args);

  error = (...args) => this.log(LEVEL.ERROR, ...args);

  // eslint-disable-next-line require-await
  log = async (level, ...args) => {
    if (LOG_LEVEL[level] >= logLevel) {
      const message = buildMessage(level, this.options, ...args);

      global.console.log(message);

      if (this.firebase) {
        this.firebase.sendWebLog({
          message,
          timestamp: this.firebase.getTimeStamp()
        }).then(() => {
          global.console.log('Posted log');
        })
        .catch((err) => {
          global.console.log(err);
        });
      }
    }
  };
}

export default Logger;
