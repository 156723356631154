import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

export const Slide = styled(animated.div)`
  height: 100%;
  width: 100%;
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
`;

export const StyledSlider = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

// Slider
const Slider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Transitions
  const transitions = useTransition(
    images[currentIndex],
    item => {
      return item.id;
    },
    {
      from: {
        opacity: 0.5,
        transform: 'scale(1.5)',
      },
      enter: {
        opacity: 1,
        transform: 'scale(1)',
      },
      leave: {
        opacity: 0.5,
      },
      config: {
        mass: 1,
        tension: 50,
        friction: 100,
      },
    }
  );

  useEffect(() => {
    setInterval(() => {
      setCurrentIndex(prevIndex => {
        if (prevIndex + 1 >= images.length) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 10000);
  }, []);

  return (
    <StyledSlider>
      {transitions.map(({ item, props, key }) => (
        <Slide key={key} style={{ ...props }} image={item.url} />
      ))}
    </StyledSlider>
  );
};

export default Slider;
