const GROUP_OF_TWO_SLICE = -2;
const GROUP_OF_THREE_SLICE = -3;

function delay (amount, shouldReject = true) {
  return new Promise((resolve, reject) => {
    global.setTimeout(function onTimeout () {
      if (shouldReject) {
        reject(new Error(`Timeout of ${amount}ms exceeded`));
      } else {
        resolve(`Timeout of ${amount}ms exceeded`);
      }
    }, amount);
  });
}

function getNowFormatted () {
  const now = new Date();
  const year = now.getFullYear();
  const month = `0${now.getMonth() + 1}`.slice(GROUP_OF_TWO_SLICE);
  const day = `0${now.getDate()}`.slice(GROUP_OF_TWO_SLICE);
  const hours = `0${now.getHours()}`.slice(GROUP_OF_TWO_SLICE);
  const minutes = `0${now.getMinutes()}`.slice(GROUP_OF_TWO_SLICE);
  const seconds = `0${now.getSeconds()}`.slice(GROUP_OF_TWO_SLICE);
  const milliseconds = `00${now.getMilliseconds()}`.slice(GROUP_OF_THREE_SLICE);

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

function isPrimitive (arg) {
  return arg !== Object(arg);
}

function buildMessage (level, options, ...args) {
  const {context, showTimestamp, prettyStringify} = options;
  const contextParts = Array.isArray(context) ? context : [context];
  let parts = [level, ...contextParts];

  if (showTimestamp) {
    parts = [
      level,
      getNowFormatted(),
      ...contextParts
    ];
  }

  const messagePrefix = parts.filter(Boolean).reduce((acc, item) => `${acc}[${item}]`, '');
  const messageBody = args.reduce((acc, item) => {
    if (isPrimitive(item)) {
      return `${acc} ${item}`.trim();
    }

    if (item instanceof Error) {
      return `${acc} ${item.stack}`.trim();
    } else if (typeof item === 'object') {
      const stringifiedItem = JSON.stringify(item, null, prettyStringify ? 2 : undefined);

      return `${acc} ${stringifiedItem}`.trim();
    }

    return `${acc} ${item.toString()}`.trim();
  }, '');

  return `${messagePrefix} ${messageBody}`.trim();
}

export {
  buildMessage,
  delay,
  getNowFormatted,
  isPrimitive
};
