import { If } from "common";
import React from "react";
import VideoCVCardTitle from "./VideoCVCardTitle";

const VideoCVCoverCard = ({ data, isHeader }) => (
  <React.Fragment>
    <VideoCVCardTitle data={data} />
    <div>
      <If
        condition={!isHeader}
        render={() => (
          <React.Fragment>
            <If
              condition={data.customSubtitle}
              render={() => (
                <p><strong>{data.customSubtitle}</strong></p>
              )}
            />
            <div sx={12} className="font-size-xs multiline-ellipsis">
              {data.description}
            </div>
          </React.Fragment>
        )}
      />
    </div>
  </React.Fragment>
);

export default VideoCVCoverCard;
