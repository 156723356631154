import { useState, useCallback } from 'react';

const useLocalTrackState = (localVideoTrack, localAudioTrack) => {
  const [localVideoStatus, setLocalVideoStatus] = useState(false);
  const [localAudioStatus, setLocalAudioStatus] = useState(false);

  const setLocalStreamStatus = useCallback(async (isVideo, isOn) => {
    if (isVideo) {
      await localVideoTrack.setEnabled(!isOn);
      setLocalVideoStatus(!isOn);
    } else {
      localAudioTrack.setVolume(isOn ? 0 : 100);
      localAudioTrack.setEnabled(!isOn);
      setLocalAudioStatus(!isOn);
    }
  }, [localVideoTrack, localAudioTrack]);

  const setLocalVideo = useCallback(async (isOn) => {
    await localVideoTrack.setEnabled(Boolean(isOn));
    setLocalVideoStatus(Boolean(isOn));
  }, [localVideoTrack, setLocalVideoStatus]);

  const setLocalAudio = useCallback((isOn) => {
    localAudioTrack.setVolume(isOn ? 100 : 0);
    localAudioTrack.setEnabled(Boolean(isOn));
    setLocalAudioStatus(Boolean(isOn));
  }, [localAudioTrack, setLocalAudioStatus]);

  return {
    localStreamStatus: {
      video: localVideoStatus,
      audio: localAudioStatus
    },
    setLocalStreamStatus,
    setLocalVideo,
    setLocalAudio
  };
}

export default useLocalTrackState;
