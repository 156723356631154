import React, { Component } from "react";
import { node } from "prop-types";
import { withFirebase } from "../firebase";

import {LOG_LEVEL} from '../../constants/logger';
import Logger from '../../service/logger';

Logger.setLevel(LOG_LEVEL.debug);

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  static displayName = "ErrorBoundary";

  static propTypes = {
    children: node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.logger = new Logger({
      context : 'vidoloError'
    }, this.props.firebase);
  }

  componentDidCatch(error) {
    this.logger.error(error);
  }

  render() {
    const { children } = this.props;

    // Return children components in case of no error
    return children;
  }
}

export default withFirebase(ErrorBoundary);
