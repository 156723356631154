import React, { useState, useCallback } from "react";
import classnames from "classnames";
import sortBy from "lodash/sortBy";
import VideoControlIcon from "./VideoControlIcon";
import { VIDEO_COMMAND_TYPE } from "../../constants/video";
import { If } from "common";
import { displayInitialsCreator } from "helper/avatar-creator";

const VideoParticipantList = ({
  list,
  sendMessageToUser,
  sendMessageToChannel,
  setCommonState,
  getStream,
  userAgoraId,
  isModerator
}) => {
  const [activeItem, setActiveItem] = useState();

  const handleOnClick = useCallback(
    (id) => {
      if (activeItem === id) {
        setActiveItem(null);
      } else {
        setActiveItem(id);
      }
    },
    [activeItem]
  );
  return (
    <React.Fragment>
      {sortBy(list, [(item) => item.user.displayName.toLowerCase()])
        .filter((item) => item.join)
        .map((participant) => {
          const currentParticipantIndex = list.findIndex(
            (p) =>
              Number(participant.user.ticketAgoraId) ===
              Number(p.user.ticketAgoraId)
          );
          const currentStream = getStream(
            Number(participant.user.ticketAgoraId)
          );
          const isVideoOn = Number(participant.user.ticketAgoraId) === Number(userAgoraId) && participant.video || Boolean(currentStream && currentStream.videoTrack);
          const isAudioOn = Number(participant.user.ticketAgoraId) === Number(userAgoraId) && participant.audio || Boolean(currentStream && currentStream.audioTrack);
          return (
            <div
              className={`participant-item online ${
                activeItem === participant.user.ticketAgoraId ? "active" : ""
              }`}
              key={participant.user.ticketAgoraId}
              onClick={() => {
                handleOnClick(participant.user.ticketAgoraId);
              }}
            >
              <div className="participant-item-row">
                <div
                  className={`participant-avatar ${
                    !participant.user.imageUrl ? "custom-avatar" : ""
                  }`}
                >
                  {participant.user.imageUrl ? (
                    <img src={participant.user.imageUrl} />
                  ) : (
                    displayInitialsCreator(participant.user.displayName)
                  )}
                </div>
                <div className="participant-title">
                  <div>{participant.user.displayName}</div>
                  <div className="participant-status">
                    <If
                      condition={Boolean(
                        currentStream && currentStream.audioTrack
                      )}
                      otherwise={() => (
                        <div className="participant-status-icon disable">
                          <i className="icon-MicrophoneOff" />
                        </div>
                      )}
                      render={() => (
                        <div className="participant-status-icon">
                          <i className="icon-Microphone" />
                        </div>
                      )}
                    />
                    <If
                      condition={Boolean(
                        currentStream && currentStream.videoTrack
                      )}
                      otherwise={() => (
                        <div className="participant-status-icon disable">
                          <i className="icon-CameraOff" />
                        </div>
                      )}
                      render={() => (
                        <div className="participant-status-icon">
                          <i className="icon-Camera" />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="participant-item-actions">
                <VideoControlIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    if (participant.videoRequestWaiting) {
                      sendMessageToUser(
                        {
                          action: VIDEO_COMMAND_TYPE.UNMUTE_VIDEO_REVOKE,
                          targetId: Number(participant.user.ticketAgoraId),
                          time: new Date().getTime(),
                        },
                        Number(participant.user.ticketAgoraId)
                      );

                      list[currentParticipantIndex].videoRequestWaiting = false;

                      setCommonState({
                        participantList: list,
                      });
                    } else {
                      sendMessageToUser(
                        {
                          action: participant.video
                            ? VIDEO_COMMAND_TYPE.MUTE_VIDEO
                            : VIDEO_COMMAND_TYPE.UNMUTE_VIDEO,
                          targetId: Number(participant.user.ticketAgoraId),
                          time: new Date().getTime(),
                        },
                        Number(participant.user.ticketAgoraId)
                      );

                      list[currentParticipantIndex].videoRequestWaiting = true;
                      setCommonState({
                        participantList: list,
                      });
                    }
                  }}
                  className={classnames("video-control-btn participant-btn", {
                    disabled: Boolean(participant.lock),
                    "red-bg-btn": !Boolean(isVideoOn),
                  })}
                  isOn={Boolean(isVideoOn)}
                  title="Video"
                  isWaiting={participant.videoRequestWaiting}
                  onIcon={<i className="icon-Camera" />}
                  offIcon={<i className="icon-CameraOff" />}
                />
                <VideoControlIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    if (participant.audioRequestWaiting) {
                      sendMessageToUser(
                        {
                          action: VIDEO_COMMAND_TYPE.UNMUTE_REVOKE,
                          targetId: Number(participant.user.ticketAgoraId),
                          time: new Date().getTime(),
                        },
                        Number(participant.user.ticketAgoraId)
                      );

                      list[currentParticipantIndex].audioRequestWaiting = false;

                      setCommonState({
                        participantList: list,
                      });
                    } else {
                      sendMessageToUser(
                        {
                          action: participant.audio
                            ? VIDEO_COMMAND_TYPE.MUTE
                            : VIDEO_COMMAND_TYPE.UNMUTE,
                          targetId: Number(participant.user.ticketAgoraId),
                          time: new Date().getTime(),
                        },
                        Number(participant.user.ticketAgoraId)
                      );

                      list[currentParticipantIndex].audioRequestWaiting = true;
                      setCommonState({
                        participantList: list,
                      });
                    }
                  }}
                  className={classnames("video-control-btn participant-btn", {
                    disabled: Boolean(participant.lock),
                    "red-bg-btn": !Boolean(isAudioOn),
                  })}
                  isOn={Boolean(isAudioOn)}
                  title="Audio"
                  isWaiting={participant.audioRequestWaiting}
                  onIcon={<i className="icon-Microphone" />}
                  offIcon={<i className="icon-MicrophoneOff" />}
                />
                <VideoControlIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    if (participant.share && Number(participant.user.ticketAgoraId) !== Number(userAgoraId)) {
                      setCommonState({
                        alertNotification: {
                          title: isModerator ? 'Give presentation to host' : 'Make me presenter',
                          isOpen: true,
                          message: "REQUEST_REVOKE_PRESENT",
                          action: () => {
                            sendMessageToChannel(
                              {
                                action: VIDEO_COMMAND_TYPE.REVOKE_PRESENTATION,
                                targetId: Number(
                                  participant.user.ticketAgoraId
                                ),
                                time: new Date().getTime(),
                              },
                              Number(participant.user.ticketAgoraId)
                            );
                          },
                        },
                      });
                    } else {
                      if (participant.shareRequestWaiting) {
                        sendMessageToChannel(
                          {
                            action: VIDEO_COMMAND_TYPE.REVOKE_PRESENTATION,
                            targetId: Number(participant.user.ticketAgoraId),
                            time: new Date().getTime(),
                          },
                          Number(participant.user.ticketAgoraId)
                        );

                        list[
                          currentParticipantIndex
                        ].shareRequestWaiting = false;
                        setCommonState({
                          participantList: list,
                        });
                      } else if (Number(participant.user.ticketAgoraId) === Number(userAgoraId)) {
                        if (participant.share) {
                          sendMessageToChannel(
                            {
                              action: VIDEO_COMMAND_TYPE.REVOKE_PRESENTATION,
                              targetId: Number(participant.user.ticketAgoraId),
                              time: new Date().getTime(),
                            },
                            Number(participant.user.ticketAgoraId)
                          );
                        } else {
                          sendMessageToChannel({
                            action: VIDEO_COMMAND_TYPE.PRESENTATION_APPROVED,
                            time: new Date().getTime(),
                          });
                        }
                      } else {
                        setCommonState({
                          alertNotification: {
                            title: "Change presenter",
                            user: participant.user,
                            isOpen: true,
                            message: "REQUEST_PRESENT_SOMEONE",
                            action: () => {
                              sendMessageToUser(
                                {
                                  action: VIDEO_COMMAND_TYPE.PRESENT,
                                  targetId: Number(
                                    participant.user.ticketAgoraId
                                  ),
                                  time: new Date().getTime(),
                                },
                                Number(participant.user.ticketAgoraId)
                              );

                              list[
                                currentParticipantIndex
                              ].shareRequestWaiting = true;
                              setCommonState({
                                participantList: list,
                              });
                            },
                          },
                        });
                      }
                    }
                  }}
                  className={classnames("video-control-btn participant-btn", {
                    disabled: Boolean(participant.lock),
                    'video-control-present-active' : Boolean(participant.share)
                  })}
                  isOn={Boolean(participant.share)}
                  title="Present"
                  isWaiting={participant.shareRequestWaiting}
                  onIcon={<i className="icon-PersonPresent" />}
                  offIcon={<i className="icon-PersonPresent" />}
                />
                <VideoControlIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    sendMessageToUser(
                      {
                        action: participant.enabled
                          ? VIDEO_COMMAND_TYPE.DISABLE
                          : VIDEO_COMMAND_TYPE.ENABLE,
                        targetId: Number(participant.user.ticketAgoraId),
                        time: new Date().getTime(),
                      },
                      Number(participant.user.ticketAgoraId)
                    );
                  }}
                  className={classnames("video-control-btn participant-btn", {
                    "red-bg-btn": !Boolean(participant.enabled),
                  })}
                  isOn={Boolean(participant.enabled)}
                  title="Kick"
                  onIcon={<i className="icon-Lock" />}
                  offIcon={<i className="icon-Lock" />}
                ></VideoControlIcon>
              </div>
            </div>
          );
        })}
      <hr />
      <div className="participant-item-sub-title">
        <span>NOT JOINED</span>
      </div>
      {sortBy(list, [(item) => item.user.displayName.toLowerCase()])
        .filter((item) => !item.join && !item.user.isCamera)
        .map((participant) => {
          return (
            <div
              className="participant-item"
              key={participant.user.ticketAgoraId}
            >
              <div className="participant-item-row">
                <div
                  className={`participant-avatar ${
                    !participant.user.imageUrl ? "custom-avatar" : ""
                  }`}
                >
                  {participant.user.imageUrl ? (
                    <img src={participant.user.imageUrl} />
                  ) : (
                    displayInitialsCreator(participant.user.displayName)
                  )}
                </div>
                {participant.user.displayName}
              </div>
              <div className="participant-item-actions" />
            </div>
          );
        })}
    </React.Fragment>
  );
};

export default VideoParticipantList;
