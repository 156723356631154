import React, { useEffect, useRef } from "react";
import { If } from "common";
import { displayInitialsCreator } from "helper/avatar-creator";

const VideoAttendePlayer = (props) => {
  const container = useRef(null);
  useEffect(() => {
    if (!container.current) return;

    if (props.videoTrack) {
      props.videoTrack.play(container.current, { fit: "cover" });
    }

    return () => {
      if (props.videoTrack) {
        while (container.current.firstChild) {
          container.current.removeChild(container.current.firstChild);
        }
      }
    };
  }, [container, props.videoTrack]);

  const videoStatus = props.isLocalUser ? props?.streamStatus?.video : props.videoTrack?._player?.videoTrack.enabled;

  return (
    <div
      className={props.containerSize ? "" : "attendes-screens-container"}
      style={{
        width: props?.containerSize?.imageWidth + "px",
        height: props?.containerSize?.imageHeight + "px",
        position: "relative",
      }}
    >
      <div
        ref={container}
        className={`attendes-screens ${
          !videoStatus ? "video-hide" : ""
        }`}
        style={
          props?.containerSize
            ? {
                width: props?.containerSize?.imageWidth + "px",
                height: props?.containerSize?.imageHeight + "px",
                position: "relative",
              }
            : null
        }
      />
      <If
        condition={Boolean(props?.streamStatus?.audio)}
        otherwise={() => (
          <div className="audio-icon disable">
            <i className="icon-MicrophoneOff" />
          </div>
        )}
        render={() => (
          <div className="audio-icon">
            <i className="icon-Microphone" />
          </div>
        )}
      />
      <If
        condition={Boolean(videoStatus)}
        render={() => (
          <div className="attende-screen-name">{props?.user?.displayName}</div>
        )}
      />

      <div
        className="attendes-screens-holder"
        style={{
          width: props?.containerSize?.imageWidth + "px",
          height: props?.containerSize?.imageHeight + "px",
          display: Boolean(videoStatus) ? "none" : "flex",
        }}
      >
        <div
          className={`attendes-screens-holder-image ${
            !props.user?.imageUrl ? "custom-avatar" : ""
          }`}
        >
          <If
            condition={Boolean(props?.user && props.user?.isCamera)}
            otherwise={() =>
              props?.user?.imageUrl ? (
                <img src={props.user.imageUrl} />
              ) : (
                displayInitialsCreator(props?.user?.displayName)
              )
            }
            render={() => (
              <span className="attende-screen-icon">
                <i className="icon-Camera" />
              </span>
            )}
          />
        </div>
        <div className="attendes-screens-holder-title">
          <span>{props?.user?.displayName}</span>
        </div>
      </div>
    </div>
  );
};

export default VideoAttendePlayer;
