const LEVEL = {
  DEBUG   : 'debug',
  ERROR   : 'error',
  INFO    : 'info',
  WARN    : 'warning',
  WARNING : 'warning'
};

const LOG_LEVEL = {
  [LEVEL.DEBUG]   : 0,
  [LEVEL.WARN]    : 1,
  [LEVEL.WARNING] : 1,
  [LEVEL.INFO]    : 2,
  [LEVEL.ERROR]   : 99
};

const DEFAULT_OPTIONS = {
  context              : '',
  isLogShippingEnabled : false,
  inMemoryLogging      : false,
  logLevel             : LOG_LEVEL[LEVEL.INFO],
  prettyStringify      : true,
  showTimestamp        : true
};

export {
  DEFAULT_OPTIONS,
  LOG_LEVEL,
  LEVEL
};
