import React from "react";
import moment from "moment";
import { If } from "../../../common";
import VideoCVCardTitle from "./VideoCVCardTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { VIDEO_CV_TYPE } from "../../../constants/video";

const VideoCVInstitutionCard = ({ data }) => (
  <React.Fragment>
    <VideoCVCardTitle data={data} />
    <div>
      <If
        condition={data.cvSectionType === VIDEO_CV_TYPE.experience.KEY}
        render={() => (
          <div className="font-size-xs">
            {data.corporateTitle && (
              <p style={{ margin: "0" }}>{data.corporateTitle}</p>
            )}
            {data.functionalTitle && (
              <p style={{ margin: "0" }}>{data.functionalTitle}</p>
            )}
          </div>
        )}
      />
      <If
        condition={data.cvSectionType === VIDEO_CV_TYPE.education.KEY}
        render={() => (
          <div className="font-size-xs">
            {`${data.degree || ""} ${
              (data.degree && data.fieldOfStudy && " - ") || ""
            } ${data.fieldOfStudy || ""}`}
          </div>
        )}
      />
      <If
        condition={data.cvSectionType === VIDEO_CV_TYPE.volunteering.KEY}
        render={() => <div className="font-size-xs">Volunteer</div>}
      />
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="font-size-xs">
        <FontAwesomeIcon icon={faMapMarkerAlt} /> {data.city} - {data.country}
      </div>
      <div className="font-size-xs">
        <FontAwesomeIcon icon={faCalendar} />{" "}
        {`${moment(Number(data.startDate?._seconds * 1000)).format("MMM YY")} - ${moment(Number(data.endDate?._seconds * 1000)).format("MMM YY")}`}
      </div>
    </div>
  </React.Fragment>
);

export default VideoCVInstitutionCard;
