const userAgent = window.navigator.userAgent.toLowerCase();

var browserName = ((agent) => {
  switch (true) {
    case agent.indexOf("edge") > -1:
      return "MS Edge";
    case agent.indexOf("edg/") > -1:
      return "Edge ( chromium based)";
    case agent.indexOf("opr") > -1 && !!window.opr:
      return "Opera";
    case agent.indexOf("chrome") > -1 && !!window.chrome:
      return "Chrome";
    case agent.indexOf("trident") > -1:
      return "MS IE";
    case agent.indexOf("firefox") > -1:
      return "Mozilla Firefox";
    case agent.indexOf("safari") > -1:
      return "Safari";
    default:
      return "other";
  }
})(userAgent);

const isFirefox = ((agent) => agent.indexOf("firefox") > -1)(userAgent);
const isSafari = ((agent) => agent.indexOf("safari") > -1)(userAgent);
const isMSEdge = ((agent) => agent.indexOf("") > -1)(userAgent);
const isEdge = ((agent) => agent.indexOf("edg/") > -1)(userAgent);
const isOpera = ((agent) => agent.indexOf("opr") > -1)(userAgent);
const isMSIE = ((agent) => agent.indexOf("trident") > -1)(userAgent);
const isChrome = ((agent) => agent.indexOf("chrome") > -1)(userAgent);

export {
  browserName,
  isFirefox,
  isSafari,
  isMSEdge,
  isEdge,
  isOpera,
  isMSIE,
  isChrome,
};
