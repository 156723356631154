/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import { withFirebase } from "../../service/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import LastEvent from '../Events/LastEvent';
import orderBy from 'lodash/orderBy';

// core components
import {
  GridContainer,
  GridItem,
  Snackbar,
  If,
  MultiItemCarousel,
  Primary,
} from "../../common";

import productStyle from "../../assets/jss/material-kit-pro-react/views/productStyle";
import cardStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards";
import EventItem from "../Events/EventItem";
import CATEGORIES from "constants/category";
import { Link } from "react-router-dom";

const useStyles = makeStyles(productStyle);
const cardsUseStyles = makeStyles(cardStyle);

const Features = (props) => {
  const classes = useStyles();
  const cardClasses = cardsUseStyles();
  const [eventList, setEventList] = React.useState(null);
  const [lastEvent, setLastEvent] = React.useState(null);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    props.firebase.getFeatureEvents((list) => {
      setEventList(list);
      setLoading(false);
    });

    let subscribe = null;
    let subscribeHostEvents = null;
    const listener = props.firebase.onAuthUserListener((authUser) => {
      if (authUser) {
        const currentDate = new Date().getTime() / 1000;
        subscribe = props.firebase.events(authUser.uid).onSnapshot((snap) => {
          const data = snap.docs.map((doc) => doc.data()).map(item => ({...item.event, userAgoraId : item.event.ticketAgoraId}));

          if (authUser.isPro) {
            subscribeHostEvents = props.firebase
              .hostEvents(authUser.uid)
              .onSnapshot((snap) => {
                const hostData = snap.docs.map((doc) => doc.data()).map(item => ({...item, userAgoraId : item.eventOwnerAgoraId}));

                const sortedData = orderBy([...hostData, ...data].filter(fData => moment(fData.eventDate.toDate()).add(fData.eventDuration, 'seconds').unix() >= currentDate),
                  item => moment(item.eventDate.toDate()).unix());
                setLastEvent(sortedData);
              });
          } else {
            const sortedData = orderBy(data.filter(fData => moment(fData.eventDate.toDate()).add(fData.eventDuration, 'seconds').unix() >= currentDate),
              item => moment(item.eventDate.toDate()).unix());
            setLastEvent(sortedData);
          }
        });
      }
    });

    return () => {
      listener();
      if (subscribe) {
        subscribe();
      }

      if (subscribeHostEvents) {
        subscribeHostEvents();
      }
    };
  }, []);

  return (
    <If
      condition={Boolean(eventList)}
      otherwise={() => (
        <div className="vidiolo-loading">
          <CircularProgress />
          <Primary>Loading...</Primary>
        </div>
      )}
      render={() => (
        <div className={classes.productPage}>
          <div className={classNames(classes.section, classes.sectionGray)}>
            <div className={classes.container}>
              <div className={classNames(classes.main)}>
                <GridContainer>
                  <If
                    condition={eventList.length === 0 && !loading}
                    render={() => (
                      <Snackbar
                        message={
                          <span>
                            To browse and buy events please login to the Vidiolo
                            App which is available on the Google Play Store or
                            in the Apple App Store if you haven’t downloaded it
                            yet.
                          </span>
                        }
                        color="info"
                        icon="info_outline"
                      />
                    )}
                  />
                  <If
                    condition={Boolean(lastEvent && lastEvent.length)}
                    render={() => (
                      <LastEvent
                        event={lastEvent[0]}
                        agoraUserId={lastEvent[0].userAgoraId}
                      />
                    )}
                  />
                  {eventList &&
                    Object.values(eventList).map((eventItem) => (
                      <GridItem xs={12} key={eventItem.categoryKey}>
                        <h4
                          style={{
                            marginTop: "20px",
                            marginBottom: "-10px",
                            paddingLeft: "20px",
                            fontWeight: 500,
                            color: "#000000",
                          }}
                        >
                          <Link
                            style={{ color: "#000000" }}
                            to={`/category/${
                              CATEGORIES[eventItem.categoryKey].key
                            }`}
                          >
                            {CATEGORIES[eventItem.categoryKey].title}
                          </Link>
                        </h4>
                        <MultiItemCarousel>
                          {eventItem.events.map((data) => (
                            <EventItem
                              key={data.eventId}
                              event={data}
                              cardClasses={cardClasses}
                              agoraUserId={data.ticketAgoraId}
                              isListItem
                            />
                          ))}
                        </MultiItemCarousel>
                      </GridItem>
                    ))}
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default withFirebase(Features);
