import React from "react";
import { Provider } from "react-redux";

import bootrapService from "./service/store/storeService";
import RouteContainer from "./routeContainer";
import ErrorBoundary from "service/errorHandler";

const App = () => {
  const store = bootrapService();

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <RouteContainer />
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
