import {
  faUser,
  faEnvelopeOpenText,
  faBuilding,
  faGraduationCap,
  faHandsHelping,
  faLanguage,
  faHandSparkles,
  faIdCard,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";

const VIDEO_COMMAND_TYPE = {
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
  MUTE: "MUTE",
  UNMUTE: "UNMUTE",
  MUTE_VIDEO: "MUTE_VIDEO",
  UNMUTE_VIDEO: "UNMUTE_VIDEO",
  PRESENT: "PRESENT",
  REVOKE_PRESENTATION: "REVOKE_PRESENTATION",
  PRESENTATION_APPROVED: "PRESENTATION_APPROVED",
  STATUS_REQUEST: "STATUS_REQUEST",
  STATUS_RESPONSE: "STATUS_RESPONSE",
  HEARTBEAT: "HEARTBEAT",
  UNMUTE_REVOKE: "UNMUTE_REVOKE",
  UNMUTE_REJECT: "UNMUTE_REJECT",
  UNMUTE_VIDEO_REVOKE: "UNMUTE_VIDEO_REVOKE",
  UNMUTE_VIDEO_REJECT: "UNMUTE_VIDEO_REJECT",
  PRESENT_REJECT: "PRESENT_REJECT",
};

const MEETING_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  STARTED: "STARTED",
  IN_END_OF_MEETING: "IN_END_OF_MEETING",
  IN_EXTRA_TIME: "IN_EXTRA_TIME",
  COMPLETED: "COMPLETED",
  END_OF_SESSION_WITHOUT_HOST: "END_OF_SESSION_WITHOUT_HOST",
  FORCE_END_MEETING: "FORCE_END_MEETING",
  REQUEST_PRESENT_SOMEONE: "REQUEST_PRESENT_SOMEONE",
  DUPLICATE_USER: "DUPLICATE_USER",
  CURTAIN_MEETING: "CURTAIN_MEETING",
};

const LOG_EVENT_TYPE = {
  JOIN: "JOIN",
  LEAVE: "LEAVE",
  PRESENT: "PRESENT",
  PRESENT_APPROVE: "PRESENT_APPROVE",
  REVOKE_RESENTATION: "REVOKE_RESENTATION",
  LOCK: "LOCK",
  UNLOCK: "UNLOCK",
};

const STREAM_TYPE = {
  HIGH: 0,
  LOW: 1,
};

const STREAM_ROLES = {
  HOST: "host",
  AUDIENCE: "audience",
};

const VIDEO_PROPERTIES = {
  DIMENSION: "240p_4",
  CODEC: "h264",
  MODE: "live",
};

const STREAM_FALLBACK_TYPE = {
  DISABLE: 0,
  LOW_STREAM: 1,
  AUDIO_ONLY: 2,
};

const VIDEO_CV_TYPE = {
  aboutMe: {
    KEY: "aboutMe",
    ICON: faUser,
    TITLE: "About Me",
  },
  cover: {
    KEY: "cover",
    ICON: faEnvelopeOpenText,
    TITLE: "Cover",
  },
  custom: {
    KEY: "custom",
    ICON: faEnvelopeOpenText,
    TITLE: "Custom",
  },
  experience: {
    KEY: "experience",
    ICON: faBuilding,
    TITLE: null, // To show dynamic title
  },
  education: {
    KEY: "education",
    ICON: faGraduationCap,
    TITLE: null, // To show dynamic title
  },
  volunteering: {
    KEY: "volunteering",
    ICON: faHandsHelping,
    TITLE: null, // To show dynamic title
  },
  languages: {
    KEY: "languages",
    ICON: faLanguage,
    TITLE: "Languages",
  },
  skills: {
    KEY: "skills",
    ICON: faHandSparkles,
    TITLE: "Skills",
  },
  licenses: {
    KEY: "licenses",
    ICON: faIdCard,
    TITLE: "Licenses",
  },
  certificates: {
    KEY: "certificates",
    ICON: faCertificate,
    TITLE: "Certificates",
  },
};

const VIDEO_RECORDING_MODE = {
  VIDEO: "video",
  VIDEO_ON_IMAGE: "videoOnImageList",
  AUDIO_ON_IMAGE: "audioOnImageList",
  AUDIO: "audio",
};

export {
  VIDEO_COMMAND_TYPE,
  MEETING_STATUS,
  LOG_EVENT_TYPE,
  STREAM_TYPE,
  VIDEO_PROPERTIES,
  STREAM_FALLBACK_TYPE,
  STREAM_ROLES,
  VIDEO_CV_TYPE,
  VIDEO_RECORDING_MODE,
};
